import React from "react";

export default function Slider() {
  return (
    <div
      id="carouselExampleCaptions"
      className="carousel slide slider"
      data-bs-ride="carousel"
      data-bs-interval="2500"
    >
      {/* SLIDES */}
      <div className="carousel-inner">
        {/* Slide 1 */}
        <div className="carousel-item active">
          <img
            src="images/grad1.jpg"
            className="d-block w-100"
            alt="Students"
          />
          <div className="carousel-caption d-md-block">
            <h5>Celebrating Academic Excellence</h5>
            <p>
              A joyous moment as graduates come together to honor their
              achievements and hard work.
            </p>
          </div>
        </div>

        {/* Slide 2 */}
        <div className="carousel-item">
          <img
            src="images/slider2.jpg"
            className="d-block w-100"
            alt="Construction"
          />
          <div className="carousel-caption d-md-block">
            <h5>New Campus Expansion</h5>
            <p>Building the future of education.</p>
          </div>
        </div>

        {/* Slide 3 */}
        <div className="carousel-item">
          <img
            src="images/gallery/academics/IMG-20250326-WA0040.jpg"
            className="d-block w-100"
            alt="Graduation"
          />
          <div className="carousel-caption d-md-block">
            <h5>State-of-the-Art Labs</h5>
            <p>Hands-on learning with cutting-edge technology.</p>
          </div>
        </div>

        {/* Slide 4 */}
        <div className="carousel-item">
          <img
            src="images/mechanical.jpg"
            className="d-block w-100"
            alt="Mechanical Lab"
          />
          <div className="carousel-caption d-md-block">
            <h5>Automotive Mechanics in Action</h5>
            <p>
              Training future experts with real-world automotive repair and
              diagnostics.
            </p>
          </div>
        </div>

        {/* Slide 5 */}
        <div className="carousel-item">
          <img
            src="images/teachers.jpg"
            className="d-block w-100"
            alt="Board Meeting"
          />
          <div className="carousel-caption d-md-block">
            <h5>Community and Team Spirit</h5>
            <p>
              Celebrating milestones and fostering a sense of unity among staff
              and students.
            </p>
          </div>
        </div>
      </div>

      {/* INDICATORS */}
      <div className="carousel-indicators">
        <button
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide-to={0}
          className="active"
          aria-current="true"
          aria-label="Slide 1"
        />
        <button
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide-to={1}
          aria-label="Slide 2"
        />
        <button
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide-to={2}
          aria-label="Slide 3"
        />
        <button
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide-to={3}
          aria-label="Slide 4"
        />
        <button
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide-to={4}
          aria-label="Slide 5"
        />
        <button
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide-to={5}
          aria-label="Slide 6"
        />
      </div>

      {/* CONTROL BUTTONS */}
      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleCaptions"
        data-bs-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true" />
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleCaptions"
        data-bs-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true" />
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );
}
