import React from "react";
import departmentsData from "../data/departments";
import Header from "../components/header";

function DepartmentsPage() {
  // Assuming departmentsData is an array of department objects
  const departments = departmentsData;

  if (!departments || departments.length === 0) {
    return (
      <div className="departments-page">
        <h2>Departments</h2>
        <p>No departments listed yet.</p>
      </div>
    );
  }

  return (
    <>
      <Header page={"academics"} />

      <div className="departments-page">
        <section className="page-header">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="content">
                  <h1 className="page-name">Departments</h1>
                  <p>Explore our diverse academic departments</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="departments-listing">
          <div className="container">
            <div className="row">
              {departments.map((department, index) => (
                <div key={index} className="col-lg-4 col-md-6 mb-4">
                  <a
                    href={`/departments/${
                      department.slug ||
                      department.name.toLowerCase().replace(/ /g, "-")
                    }`}
                    className="rounded-pill"
                  >
                    <div className="department-item">
                      {department.image && (
                        <div className="department-image">
                          <img
                            src={department.image}
                            alt={department.name}
                            className="img-fluid"
                          />
                        </div>
                      )}
                      <div className="department-content">
                        <h3>{department.name}</h3>
                        {department.head && (
                          <p className="department-head">
                            <strong>Head of Department:</strong>{" "}
                            {department.head}
                          </p>
                        )}
                        <p className="department-description">
                          {department.description}
                        </p>
                       
                      </div>
                      <div className="department-footer">
                        <div className="btn btn-outline-success">
                          Learn More <i className="fa fa-angle-right ml-2"></i>
                        </div>
                      </div>
                    </div>{" "}
                  </a>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default DepartmentsPage;
