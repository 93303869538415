import React from "react";
import Header from "../components/header";

export default function AcademicRegistrarsMessage() {
  return (
    <>
      <Header page={"academics"} />

      {/* ACADEMIC REGISTRAR'S MESSAGE HERO */}
      <section className="gallery-hero mb-5">
        <div className="container">
          <h1 className="text-center blue gallery-title">
            ACADEMIC REGISTRAR'S <span className="red">MESSAGE</span>
          </h1>
          <div className="title-underline my-3"></div>
          
        </div>
      </section>

      <div className="container mb-5">
        <div className="row">
          {/* Academic Registrar's Image Column */}
          <div className="col-lg-4 mb-4">
            <div className="card border-0 shadow h-100">
              <div className="registrar-img overflow-hidden">
                <img 
                  src="/images/staff/doreen.jpg" 
                  alt="Ms. Kituyi Doreen, Academic Registrar at Kyadondo Technical Institute"
                  className="img-fluid"
                />
              </div>
              <div className="card-body text-center bg-light">
                <h2 className="card-title capitalize blue fw-bold">Ms. Kituyi Doreen</h2>
                <h3 className="card-subtitle mb-2 text-muted">ACADEMIC REGISTRAR</h3>
                <div className="d-flex justify-content-center gap-3 mt-3">
                  <a href="#" className="btn btn-sm btn-outline-primary">
                    <i className="bi bi-envelope-fill me-1"></i> Contact
                  </a>
                  <a href="#" className="btn btn-sm btn-outline-secondary">
                    <i className="bi bi-linkedin me-1"></i> Profile
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/* Message Content Column */}
          <div className="col-lg-8">
            <div className="card border-0 shadow message-card h-100">
              <div className="card-body p-4">
                <div className="message-header mb-4">
                  <h2 className="blue fw-bold mb-3">Message from the Office of the Academic Registrar</h2>
                  <div className="separator-line"></div>
                </div>
                
                <div className="message-content">
                  <p className="mb-1">
                    To the Global Community,
                  </p>
                  <p className="mb-3">
                    In today’s fast-changing world, hands-on skills are more valuable than ever before. The era of practical expertise is here, and technical education is no longer an alternative for failures rather a pathway to success. Skilled professionals are in high demand across industries, making technical and business courses essential for sustainable careers.
                  </p>
                  <p className="mb-3">
                    At Kyadondo Technical Institute, we pride ourselves on being the only school dedicated to true skills development. Our institution provides not just practical training but also mentorship, counselling, and guidance to ensure our students grow both professionally and personally. We instil discipline, and our students consistently uphold high standards when assigned for internships at workplaces.
                  </p>
                  <p className="mb-3">
                    Our programs cover both technical and business courses at affordable fees, making education accessible to all. We also offer full and half bursaries to support students in achieving their dreams without financial barriers.
                  </p>
                  <p className="mb-3">
                    Join Kyadondo Technical Institute today and secure your future in the world of hands-on skills. 
                  </p>
                  <p className="fw-bold red mb-3">
                  "The future belongs to those who can do!"
                  </p>
                  
                  <div className="signature mt-4">
                    <div className="fw-bold fs-5 blue">Ms. Kituyi Doreen</div>
                    <div className="text-muted">Academic Registrar, Kyadondo Technical Institute</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Call to Action Section */}
        <div className="row mt-5">
          <div className="col-12">
            <div className="card bg-blue text-white border-0 shadow">
              <div className="card-body p-4 text-center">
                <h3 className="mb-3">Ready to secure your future with us?</h3>
                <p className="mb-4">Explore our programs and take the first step toward a sustainable career.</p>
                <div className="d-flex justify-content-center gap-3">
                  <a href="/courses" className="btn btn-light red fw-bold">
                    Explore Programs
                  </a>
                  <a href="https://admissions.kti.ac.ug" className="btn btn-outline-light fw-bold">
                    Apply Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Additional Styling */}
      <style jsx>{`
        .registrar-img {
          height: 300px;
          width: 100%;
        }
        
        .registrar-img img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: transform 0.3s ease;
        }
        
        .registrar-img:hover img {
          transform: scale(1.05);
        }
        
        .separator-line {
          height: 4px;
          width: 80px;
          background-color: var(--bs-secondary);
          margin-bottom: 20px;
        }
        
        .message-card {
          position: relative;
          overflow: hidden;
        }
        
        .signature {
          border-top: 1px solid rgba(0,0,0,0.1);
          padding-top: 15px;
        }
      `}</style>
    </>
  );
}