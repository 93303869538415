import React from "react";
import Header from "../components/header";

export default function Admission() {
  return (
    <div className="admission">
      <Header page="admission" />

      <div className="container my-5">
        <div className="jumbotron">
          <h1 className="blue text-center">ADMISSION</h1>
          <p className="lead blue py-3">
            Here's <span className="yellow">everything</span> you need to know
            to get started with us.
          </p>
        </div>

        <div className="alert alert-warning" role="alert">
          Hostel and Meals services are available at a cost of UGX 450,000 for Certificate students and UGX 550,000 for diploma students but OPTIONAL for those with alternatives.
        </div>

        <div className="alert alert-info" role="alert">
          All students on partial sponsorship shall pay 60% of the total tuition
          & functional fees with caution fees of UGX 57,500 per semester or
          term.
        </div>

        <div className="alert alert-success" role="alert">
          UBTEB Registration fees are COMPULSORY for every student & payable per
          sitting. The amount is communicated by UBTEB every time students are
          going to register.
        </div>

        <div className="alert alert-secondary" role="alert">
          All students wishing to reside in the Hostels shall be required to
          make bookings with the office of the DEANS OF STUDENTS Affairs ON
          0789217292/0753730063 two weeks to the start of the semester/term.
        </div>

        <ul className="list-group my-4">
          <li className="list-group-item">
            A medical report detailing your health status is obtainable at the
            Institute's Health facility at a fee of UGX 50,000.
          </li>
          <li className="list-group-item">
            A Workshop fee of UGX 150,000 is paid per student per term to their
            respective Department.
          </li>
          <li className="list-group-item">
            It's compulsory for every Technical student to buy an overall from
            the school on arrival, the cost of this overall is UGX 40,000.
          </li>
          <li className="list-group-item">
            A ream of photocopying papers is also payable per student, per
            semester.
          </li>
        </ul>

        {/* FEES PAYMENT */}
        <h3 className="blue">
          Fees <span className="red">Payment</span>
        </h3>
        <ul>
          <li>
            Payment of fees is obligatory and it's done at the beginning of or
            before each semester.
          </li>
          <li>
            All payments MUST be made in CENTENARY BANK on the school bank
            account stated below <br />
            <p className="bold">
              <span className="blue">Account Name:</span> KYADONDO TECHNICAL
              INSTITUTE
            </p>
            <p className="bold">
              <span className="blue">Account No:</span> 3100045410.
            </p>
          </li>
          <li>
            Copies of payments should be presented to the office of the bursar &
            academic registrar for issuance of registration card, & Lecture
            card.
          </li>
          <li>
            You will be issued with the clearance card, registration card,
            lecture card and a medical card at the beginning of the
            semester/Term. You're required to pick all these cards from both the
            Academic registrar and Bursar's offices.
          </li>
          There shall be no refund whatsoever except where prior permission has
          been granted by the principal for such a request to be considered, an
          application MUST have been lodged four weeks in advance at the
          beginning of the semester/Term. At any rate only (50%) shall be
          refunded. The Institute reserves the right to vary fees depending on
          the prevailing situation in the country.
        </ul>

        <h3 className="blue mt-3 mb-2">
          Attendance of Lectures, Practicals or Courseworks
        </h3>
        <ul>
          <li>
            Attendance of Lectures is MANDATORY & COMPULSORY to all students
            without any excuse, since this is the main reason for your admission
            here, it should be done according to various timetables & failure to
            comply as expected, any student is liable to suspension or
            expulsion.{" "}
          </li>
          <li>
            All class assessments, coursework tests, take home coursework is
            MANDATORY to all students without excuses.
          </li>
          <li>
            All practical's arranged either within school or outside school are
            MANDATORY to all students in every course without any exemption.
          </li>
          <li>
            Each student MUST sign on attendance list or register each time
            he/she attend class without fail. No student is allowed to sign on
            behalf of others.
          </li>
          <li>
            All students MUST sit for internal examinations prepared by the
            Institution. All students MUST register for National examinations
            i.e. UBTEB, DIT, any other Examinations as directed by the institute
            for both Diploma & Certificates are MANDATORY & COMPULSORY to all
            registered students of this institution. No exemption shall be given
            to any student who academically qualify to sit for these
            examinations.
          </li>
        </ul>

        {/* ACCOMODATION */}
        <h3 className="blue mt-2 mb-2">Accommodation</h3>
        <div style={{ marginLeft: "30px" }}>
          There is limited space for accommodation at the institute therefore;
          all students willing to stay in the hostels must contact the Dean of
          students' affairs for booking. The space will strictly be availed to
          only those who will have cleared the hostel fees and requirements on
          the first day of reporting.
          <p>
            All first year students are advised to stay in the hostels and can
            leave with permission from the Principal through the Dean of
            Students' Affairs. However, those students who may fail to stay in
            the Institution Hostels may be allowed to rent outside after clear
            clarification with the office of the Dean of Students Affairs in the
            Institute and consent from the Parent/Guardian/Sponsors.
          </p>
          <h5 className="red mb-0">Visitors</h5>
          <p>
            You should only see your visitors while at the Institute campus,
            making appointments with visitors to see you while it is lecture
            time is prohibited. While at the campus, visitors will not be
            allowed in your rooms they should be receive at the reception.
          </p>
          <h5 className="red mb-0">Cooking in Hostels</h5>
          <p>
            You're not allowed to do the cooking in the rooms because of risks
            associated with it. Any cooking item caught will be confiscated &
            the culprits will be called in the disciplinary committee.
          </p>
          <h5 className="red mb-0">
            Maintenance of Hygiene in Residences (Hostels)
          </h5>
          <p>
            It's your obligation to participate in the general hygiene of your
            area of residence. Buckets are provided for sanitary towel for
            disposal. Ensure proper use of them to avoid blockages of the
            drainage system.
          </p>
          <h5 className="red mb-0">Meals</h5>
          <p>
            We provide a menu in our dining hall, but its liable to change due
            to unreliable season. You may wish to order for special meals but
            can be arranged on special payment as the Dean of Students may guide
            you. For orderliness, you should carry your meal cards, plates,
            cups, folks, etc. However, strictly follow the meals times as
            failure may lead to missing meals.
          </p>
        </div>

        <h3 className="blue mt-3 mb-2">
          Drunkardness, Theft, Malice, Fighting, and Vulgar Language
        </h3>
        <p>
          By the virtue of your training, you're no any intoxicating drugs,
          wines or any form of drunkardness will be tolerated and if caught,
          this may can lead to dismissal from the institute. Self-respect &
          respect of one another should guide your lifestyle. Any form of
          Malice, fighting & use of vulgar language is prohibited & if caught,
          you're liable to suspension or dismissal depending on the magnitude of
          the act.
        </p>

        <h3 className="blue mt-3 mb-2">
          Social Occasions and Institute Property
        </h3>
        <p>
          In case of parties, Dances of any kind organized from outside the
          Institution environment, get permission from the Dean of students
          through the Campus Affairs Minister to allow such an arrangement to
          take place. You MUST not however take Alcohol as part of your menu.
          Drinking alcohol at the institute is an offence. Furthermore, in case
          of careless damage of the Institute's property, you will be liable to
          pay it back.
        </p>

        <h3 className="blue mt-3 mb-2">Personal Property</h3>
        <p>
          All students are responsible for their personal property. No case of
          theft, vandalism or else shall be heard if there is negligence
          discovered over the loss of own property whatsoever. Please find all
          other Institution rules & regulations in the Institute's rules &
          regulations Handbook obtainable at the Office of the Dean of Students
          Affairs. Cases of impersonation, falsification of documents or giving
          incomplete information wherever discovered, either on registration or
          afterwards, shall lead to automatic cancellation of your program,
          dismissal from the institute or prosecution
        </p>
        <p>
          <strong>Note:</strong> Discipline, creativity, hard work, and focus
          shall be of much importance throughout your studies.
        </p>

        <h3 className="mt-3 mb-2 blue">Institution Regulations</h3>
        <p>
          All students are entitled to Institution Rules & Regulations booklet.
          Obtain a copy of the information booklet which includes institute
          rules and regulations from the dean of students. Please, read these
          regulations carefully and comply with them accordingly.
        </p>
      </div>
    </div>
  );
}
