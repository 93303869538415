import Footer from './components/footer';
import Preloader from './components/preloader';
import { useLoading } from './loading_context';
import NotFound from './pages/not_found';
import Contact from './pages/contact';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Homepage from './pages/homepage';
import About from './pages/about';
import Courses from './pages/courses';
import ScrollToTop from './components/scroll_to_top';
import Admission from './pages/admission';
import CourseDetails from './pages/course_details';
import DepartmentsPage from './pages/departments';
import DepartmentDetailsPage from './pages/department_details';
import GalleryScreen from './pages/gallery';
import StaffMembers from './components/staff_members';
import PrincipalsMessage from './pages/principals_message';
import LeadershipTeam from './components/staff_members';
import DeansMessage from './pages/deans_message';
import AcademicRegistrarsMessage from './pages/ar_message';

function App() {

  const { isLoading } = useLoading();

  return (
    <div>
      {isLoading && <Preloader />}
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route path="/" element={<Homepage />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<About />} />
          <Route path="/courses" element={<Courses />} />
          <Route path="/admission" element={<Admission />} />
          <Route path="/course/:id" element={<CourseDetails />} />
          <Route path="/departments" element={<DepartmentsPage />} />
          <Route path="/departments/:departmentSlug" element={<DepartmentDetailsPage />} />
          <Route path="/gallery" element={<GalleryScreen />} />
          <Route path="/leadership" element={<LeadershipTeam />} />
          <Route path="/principal-message" element={<PrincipalsMessage />} />
          <Route path="/deans-message" element={<DeansMessage />} />
          <Route path="/ar-message" element={<AcademicRegistrarsMessage />} />
          <Route path="/gallery/:category" element={<GalleryScreen />} />

        </Routes>
        <Footer />
        <ScrollToTop />

      </BrowserRouter>
    </div>
  );
}

export default App;
