import React from "react";
import notFound from "../assets/404.png";

export default function NotFound() {
  return (
    <div className="container">
      <div className="row justify-content-center not-found">
        <div className="col-md-6 d-flex justify-content-center align-items-center">
          <img
            src={notFound}
            alt="404"
            style={{ width: "80%", height: "65%" }}
          />
        </div>

        <div
          className="col-md-6 d-flex flex-column justify-content-center"
          style={{ minHeight: "100vh" }}
        >
          <h1 className="text-warning">PAGE NOT FOUND</h1>
          <h6>
          Oh no! It looks like you've discovered the secret hideout of our missing pages. While it's great to explore, this page seems to have taken a little adventure of its own and can't be found at the moment. Fear not! You can navigate back to familiar territory by clicking the button below and continue your journey through our site. Safe travels!
          </h6>
          <a href="/" className="btn btn-dark rounded-pill mt-3">
            Go Home
          </a>
        </div>
      </div>
    </div>
  );
}
