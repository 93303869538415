import React from 'react';
import { useParams } from 'react-router-dom';
import departmentsData from '../data/departments';
import Header from '../components/header';

function DepartmentDetailsPage() {
  const { departmentSlug } = useParams();
  const department = departmentsData.find(dept => dept.slug === departmentSlug);

  if (!department) {
    return <div className="department-details-page"><h2>Department Not Found</h2><p>Sorry, department not found.</p></div>;
  }

  return (
    <div className="department-details-page">
      <Header page="departments" /> {/*  Assuming you might want "departments" to be active in header nav */}

      <section className="department-hero-section"> {/* Hero section for department name */}
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="department-hero-content">
                <h1 className="department-hero-title">{department.name}</h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="department-details-section"> {/* Main details section */}
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="department-overview-area"> {/* Overview and Head's message area */}
                {department.image && (
                  <div className="department-featured-image">
                    <img src={department.image} alt={department.name} className="img-fluid rounded" />
                  </div>
                )}
                <h2 className="section-heading">Department Overview</h2> {/* Consistent section heading style */}
                <p className="department-description">{department.description}</p>

                {department.headMessage && (
                  <div className="head-message-area"> {/* Area for head's message */}
                    <h3 className="section-heading">Message from the Head of Department</h3> {/* Consistent section heading style */}
                    <div className="head-message-content">
                      <p className="head-name"><strong>{department.head}</strong></p> {/* Style for head's name */}
                      <p className="head-message-text">{department.headMessage}</p> {/* Style for message text */}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="col-lg-4">
              <aside className="department-sidebar-area"> {/* Sidebar area */}
                <div className="department-contact-info">
                  <h3 className="sidebar-heading">Contact Information</h3> {/* Sidebar heading style */}
                  <ul className="contact-list"> {/*  List for contact info */}
                    <li className="contact-item"><strong>Head of Department:</strong> {department.head}</li> {/* Style for list items */}
                    {department.contactEmail && <li className="contact-item"><strong>Email:</strong> <a href={`mailto:${department.contactEmail}`}>{department.contactEmail}</a></li>}
                    {department.contactPhone && <li className="contact-item"><strong>Phone:</strong> {department.contactPhone}</li>}
                  </ul>
                </div>

                {/* COURSES OFFERED SECTION */}
                <div className="department-courses-area"> {/* Area for courses section */}
                  <h3 className="sidebar-heading">Courses Offered</h3> {/* Sidebar heading style */}
                  <ul className="courses-list"> {/* List for courses */}
                    {department.courses && department.courses.map((course, index) => (
                      <li key={index} className="course-item">{course}</li>
                    ))}
                  </ul>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default DepartmentDetailsPage;