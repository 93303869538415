const galleryData = {

  // ENTERTAINMENT
  entertainment: {
    title: "Entertainment",
    description: "Explore our vibrant entertainment events and activities that bring our community together.",
    images: [
      {
        id: 1,
        url: "/images/placeholder.jpg",
        title: "Annual Music Festival",
        description: "Students showcasing their musical talents at our annual festival."
      },
      {
        id: 2,
        url: "/images/placeholder.jpg",
        title: "Drama Performance",
        description: "Our drama club presenting their end-of-semester production."
      },
      {
        id: 3,
        url: "/images/placeholder.jpg",
        title: "Cultural Day",
        description: "Celebrating diverse cultures through performances and exhibitions."
      },
      {
        id: 4,
        url: "/images/placeholder.jpg",
        title: "Movie Night",
        description: "Students enjoying our monthly outdoor cinema experience."
      },
      {
        id: 5,
        url: "/images/placeholder.jpg",
        title: "Talent Show",
        description: "Showcasing the incredible talents of our student body."
      },
      {
        id: 6,
        url: "/images/placeholder.jpg",
        title: "Dance Competition",
        description: "Annual dance-off between different departments and clubs."
      }
    ]
  },

  // ACADEMICS
  academics: {
    title: "Academics",
    description: "Discover our academic excellence through various learning activities and achievements.",
    images: [
      {
        id: 1,
        url: "/images/gallery/academics/eng (1).jpg",
        title: "Engineering Workshop",
        description: "Students working on practical engineering projects."
      },
      {
        id: 2,
        url: "/images/gallery/academics/eng (2).jpg",
        title: "Engineering Workshop",
        description: "Students working on practical engineering projects."
      },
      {
        id: 3,
        url: "/images/gallery/academics/eng (3).jpg",
        title: "Engineering Workshop",
        description: "Students working on practical engineering projects."
      },
      {
        id: 4,
        url: "/images/gallery/academics/eng (4).jpg",
        title: "Engineering Workshop",
        description: "Students working on practical engineering projects."
      },
      {
        id: 5,
        url: "/images/gallery/academics/eng (5).jpg",
        title: "Engineering Workshop",
        description: "Students working on practical engineering projects."
      },
      {
        id: 6,
        url: "/images/gallery/academics/eng (6).jpg",
        title: "Engineering Workshop",
        description: "Students working on practical engineering projects."
      },

      {
        id: 7,
        url: "/images/gallery/academics/eng (7).jpg",
        title: "Engineering Workshop",
        description: "Students working on practical engineering projects."
      },

      {
        id: 8,
        url: "/images/gallery/academics/eng (8).jpg",
        title: "Engineering Workshop",
        description: "Students working on practical engineering projects."
      },

      {
        id: 9,
        url: "/images/gallery/academics/eng (9).jpg",
        title: "Engineering Workshop",
        description: "Students working on practical engineering projects."
      },

      {
        id: 10,
        url: "/images/gallery/academics/eng (10).jpg",
        title: "Engineering Workshop",
        description: "Students working on practical engineering projects."
      },

      {
        id: 11,
        url: "/images/gallery/academics/eng (11).jpg",
        title: "Engineering Workshop",
        description: "Students working on practical engineering projects."
      },

      {
        id: 12,
        url: "/images/gallery/academics/eng (12).jpg",
        title: "Engineering Workshop",
        description: "Students working on practical engineering projects."
      },

      {
        id: 13,
        url: "/images/gallery/academics/IMG-20250326-WA0035.jpg",
        title: "Engineering Workshop",
        description: "Students working on practical engineering projects."
      },

    
      {
        id: 14,
        url: "/images/gallery/academics/IMG-20250326-WA0037.jpg",
        title: "Engineering Workshop",
        description: "Students working on practical engineering projects."
      },

      {
        id: 15,
        url: "/images/gallery/academics/IMG-20250326-WA0038.jpg",
        title: "Engineering Workshop",
        description: "Students working on practical engineering projects."
      },

  
    ]
  },

  // SPORTS
  sports: {
    title: "Sports",
    description: "See our athletes in action across various sporting disciplines and competitions.",
    images: [
      {
        id: 1,
        url: "/images/placeholder.jpg",
        title: "Football Tournament",
        description: "Annual inter-departmental football championship."
      },
      {
        id: 2,
        url: "/images/placeholder.jpg",
        title: "Basketball Team",
        description: "Our basketball team during regional championships."
      },
      {
        id: 3,
        url: "/images/placeholder.jpg",
        title: "Athletics Day",
        description: "Track and field events during our annual sports day."
      },
      {
        id: 4,
        url: "/images/placeholder.jpg",
        title: "Swimming Competition",
        description: "Students competing in our Olympic-sized swimming pool."
      },
      {
        id: 5,
        url: "/images/placeholder.jpg",
        title: "Volleyball Match",
        description: "Intense volleyball match against neighboring institutions."
      },
      {
        id: 6,
        url: "/images/placeholder.jpg",
        title: "Table Tennis Tournament",
        description: "Students showcasing their table tennis skills."
      }
    ]
  },

  // CAMPUS
  campus: {
    title: "Campus Life",
    description: "Experience the vibrant daily life and beautiful facilities around our campus.",
    images: [
      {
        id: 1,
        url: "/images/placeholder.jpg",
        title: "Main Building",
        description: "Our iconic main administration building."
      },
      {
        id: 2,
        url: "/images/placeholder.jpg",
        title: "Campus Gardens",
        description: "Beautiful landscaped gardens for relaxation and study."
      },
      {
        id: 3,
        url: "/images/placeholder.jpg",
        title: "Student Center",
        description: "The hub of student activities and services."
      },
      {
        id: 4,
        url: "/images/placeholder.jpg",
        title: "Library",
        description: "Our modern library with extensive resources."
      },
      {
        id: 5,
        url: "/images/placeholder.jpg",
        title: "Cafeteria",
        description: "Spacious dining facility serving various cuisines."
      },
      {
        id: 6,
        url: "/images/placeholder.jpg",
        title: "Dormitories",
        description: "Comfortable student accommodation facilities."
      }
    ]
  }
};


export default galleryData;