const courses = [
  {
    title: "National Diploma in Electrical Engineering (NDEE)",
    description:
      "This program covers the fundamentals of electrical engineering, including power generation, transmission, and distribution systems.",
    image: "/images/engineer.jpg",
    category: 'diploma',
    overview: `The National Diploma in Electrical Engineering at Kyadondo Technical Institute offers a robust foundation in electrical engineering principles and practices. Students will gain expertise in power systems, electronic circuits, and electrical machines. This program is designed for those seeking to become proficient electrical technicians and engineers.`,
    whyChoose: `Our program offers hands-on experience in well-equipped labs and workshops. You'll be taught by experienced electrical engineers and technicians. The curriculum is designed to meet the industry's growing demands for skilled electrical professionals. Graduates are prepared for diverse roles in electrical industries and further studies.`,
    duration: "2 Years",
    entryRequirements: [
      "A Craft Certificate in Electrical Installation OR",
      "A Uganda Advanced Certificate of Education (UACE) with at least one principal pass in Physics or Mathematics."
    ],
    syllabus: [
      "Electrical Circuit Theory",
      "Electronic Devices and Circuits",
      "Electrical Machines",
      "Power Generation and Transmission",
      "Electrical Installations",
      "Control Systems",
      "Renewable Energy Systems",
      "Electrical Engineering Design and Drafting"
    ],
    careerProspects: [
      "Electrical Technician",
      "Electrical Engineer Assistant",
      "Power Systems Technician",
      "Renewable Energy Technician",
      "Maintenance Electrician",
      "Electrical Design Assistant"
    ],
    awardingBody: "Uganda Business and Technical Examinations Board (UBTEB)",
  },
  {
    title: "National Diploma in Civil Engineering (NDCE)",
    description:
      "Prepare for a career in civil engineering with courses in structural analysis, materials science, and construction management.",
    image:
      "https://studenthub.africa/app/uploads/careers/K8hpb3AumV_OpEEGdZ4l57tWeEklLJSP.jpg",
    category: 'diploma',
    overview: `The National Diploma in Civil Engineering at Kyadondo Technical Institute provides comprehensive training in the design, construction, and maintenance of infrastructure projects. This program covers structural engineering, geotechnical engineering, and transportation engineering. Prepare to build the future with a solid civil engineering education.`,
    whyChoose: `Our curriculum is project-based, providing real-world experience through simulations and site visits. Instructors are experienced civil engineers and construction professionals. The program is designed to address the infrastructure development needs of the region. Graduates are well-positioned for roles in construction, infrastructure, and government sectors.`,
    duration: "2 Years",
    entryRequirements: [
      "A Craft Certificate in Building Construction OR",
      "A Uganda Advanced Certificate of Education (UACE) with at least one principal pass in Physics or Mathematics."
    ],
    syllabus: [
      "Engineering Mechanics",
      "Structural Analysis",
      "Civil Engineering Materials",
      "Geotechnical Engineering",
      "Highway Engineering",
      "Water Resources Engineering",
      "Construction Management",
      "Surveying and Quantity Surveying"
    ],
    careerProspects: [
      "Civil Engineering Technician",
      "Construction Supervisor",
      "Site Engineer Assistant",
      "Highway Technician",
      "Structural Drafter",
      "Quantity Surveyor Assistant"
    ],
    awardingBody: "Uganda Business and Technical Examinations Board (UBTEB)",
  },
  {
    title: "National Diploma in Automobile Engineering (NDAE)",
    description:
      "Become an automotive engineer! Master vehicle technology, maintenance, and repair with our industry-focused diploma.",
    image: "/images/placeholder.png",

    category: 'diploma',
    overview: `The National Diploma in Automobile Engineering at Kyadondo Technical Institute is designed to equip you with the technical expertise and hands-on skills needed to excel in vehicle technology, maintenance, and repair. Our diploma program offers a comprehensive and industry-relevant curriculum, blending theoretical knowledge with practical training to ensure you are fully prepared for the job market or further studies.`,
    whyChoose: `Develop a strong foundation in automotive engineering principles, gain hands-on experience repairing and servicing vehicles, learn about emerging automotive technologies including electric and hybrid vehicles, and understand safety, environmental standards, and regulatory compliance.`,
    duration: "2 Years",
    entryRequirements: [
      "A Craft Certificate in Motor Vehicle Engineering or a related field OR",
      "A Uganda Advanced Certificate of Education (UACE) with at least one principal pass in relevant subjects (Math, Physics, or Technical Drawing)."
    ],
    syllabus: [
      "Automobile Mechanics & Engine Technology",
      "Electrical & Electronic Systems in Vehicles",
      "Chassis, Suspension & Transmission Systems",
      "Vehicle Diagnostics & Fault-Finding Techniques",
      "Hydraulics & Pneumatics in Automotive Systems",
      "Workshop Organization & Management",
      "Entrepreneurship & Business Management",
      "Industrial Training & Project Work"
    ],
    careerProspects: [
      "Automobile Engineer",
      "Vehicle Maintenance & Repair Specialist",
      "Fleet Manager",
      "Technical Instructor",
      "Automotive Technician (Dealerships & Garages)",
      "Entrepreneur in the Automotive Sector"
    ],
    awardingBody: "Uganda Business and Technical Examinations Board (UBTEB)",
  },
  {
    title: "National Diploma in Water & Sanitation Engineering (NDWSE)",
    description:
      "Explore water resource management, wastewater treatment, and sustainable sanitation practices in this comprehensive program.",
    image:
      "https://images.squarespace-cdn.com/content/v1/5b60bd5e7c93271a80ef6d7a/1536672837664-I47CGC2P344FCR5I0V1R/1.jpg",
    category: 'diploma',
    overview: `The National Diploma in Water & Sanitation Engineering at Kyadondo Technical Institute addresses critical global challenges in water resource management and public health. The program emphasizes sustainable water and sanitation solutions, wastewater treatment, and environmental protection. Prepare to become a steward of water resources and sanitation systems.`,
    whyChoose: `Our program includes field studies and practical projects in water treatment and sanitation facilities. Learn from environmental engineers and public health experts. The curriculum is designed to meet the growing demand for clean water and sanitation solutions. Graduates can contribute to environmental conservation and public health initiatives.`,
    duration: "2 Years",
    entryRequirements: [
      "A Craft Certificate in Plumbing or related field OR",
      "A Uganda Advanced Certificate of Education (UACE) with at least one principal pass in Physics, Chemistry, or Biology."
    ],
    syllabus: [
      "Water Resources Management",
      "Wastewater Treatment Technologies",
      "Water Supply and Distribution Systems",
      "Sanitation and Hygiene Practices",
      "Environmental Health Engineering",
      "Hydrology and Water Quality",
      "Sustainable Sanitation Solutions",
      "Public Health and Waterborne Diseases"
    ],
    careerProspects: [
      "Water and Sanitation Technician",
      "Environmental Health Officer",
      "Water Quality Analyst",
      "Wastewater Treatment Plant Operator",
      "Sanitation Project Coordinator",
      "Water Resources Assistant"
    ],
    awardingBody: "Uganda Business and Technical Examinations Board (UBTEB)",
  },
  {
    title: "National Diploma in Architecture (NDAD)",
    description:
      "Learn the principles of architectural design, building technology, and project management in this dynamic program.",
    image:
      "https://miro.medium.com/v2/resize:fit:1400/1*3qQAnftGZO1L6jN7rdTnrg.jpeg",
    category: 'diploma',
    overview: `The National Diploma in Architecture at Kyadondo Technical Institute is an immersive program into the art and science of building design. Students will develop skills in architectural drafting, spatial design, and sustainable building practices. Prepare to shape the built environment with innovative and functional designs.`,
    whyChoose: `Our program provides hands-on experience in architectural design studios and model-making workshops. Learn from practicing architects and design professionals. The curriculum emphasizes creativity, sustainability, and technological proficiency. Graduates are prepared for careers in architectural firms, construction companies, and urban planning agencies.`,
    duration: "2 Years",
    entryRequirements: [
      "A Craft Certificate in Architectural Drafting or related field OR",
      "A Uganda Advanced Certificate of Education (UACE) with at least one principal pass in Art, Technical Drawing, or Physics."
    ],
    syllabus: [
      "Architectural Design Principles",
      "Building Technology",
      "Architectural Graphics and Drafting",
      "History of Architecture",
      "Urban Design and Planning",
      "Sustainable Design and Construction",
      "Building Information Modeling (BIM)",
      "Architectural Project Management"
    ],
    careerProspects: [
      "Architectural Technician",
      "Draftsperson",
      "Design Assistant",
      "CAD Operator",
      "Building Inspector",
      "Urban Planning Assistant"
    ],
    awardingBody: "Uganda Business and Technical Examinations Board (UBTEB)",
  },
  {
    title: "National Diploma in ICT (NDICT)",
    description:
      "This program offers a deep dive into information and communication technologies, covering topics from network security to software development.",
    image: "https://www.newscraftgh.com/images/icons/3-1024x683.jpg",
    category: 'diploma',
    overview: `The National Diploma in ICT at Kyadondo Technical Institute is a comprehensive program designed to equip students with skills in Information and Communication Technologies. The program covers networking, cybersecurity, software development, and IT support. Prepare to be a leader in the digital age.`,
    whyChoose: `Our program includes hands-on labs with the latest networking and computing equipment. Learn from certified IT professionals and cybersecurity experts. The curriculum is aligned with industry certifications such as CompTIA and Cisco. Graduates are prepared for roles in IT administration, network engineering, software development, and cybersecurity.`,
    duration: "2 Years",
    entryRequirements: [
      "A Craft Certificate in ICT or related field OR",
      "A Uganda Advanced Certificate of Education (UACE) with at least one principal pass in Mathematics or Physics."
    ],
    syllabus: [
      "Computer Networks",
      "Cybersecurity Fundamentals",
      "Database Management Systems",
      "Software Development Principles",
      "Web Development Technologies",
      "Operating Systems Administration",
      "IT Project Management",
      "Cloud Computing Basics"
    ],
    careerProspects: [
      "IT Support Specialist",
      "Network Technician",
      "Cybersecurity Analyst",
      "Web Developer",
      "Database Administrator",
      "Systems Administrator"
    ],
    awardingBody: "Uganda Business and Technical Examinations Board (UBTEB)",
  },
  {
    title: "National Diploma in Computer Science (NDCS)",
    description:
      "Gain a solid foundation in computer science with a curriculum that includes programming, algorithms, and data structures.",
    image:
      "https://www.usnews.com/object/image/0000016b-e1d5-d947-a56f-f1dd0e4d0000/computerscienceprofessional.jpg?update-time=1562862880129&size=responsive640",
    category: 'diploma',
    overview: `The National Diploma in Computer Science at Kyadondo Technical Institute provides a strong theoretical and practical foundation in computer science principles. The program covers algorithms, data structures, software engineering, and programming languages. Prepare to innovate in the world of computing.`,
    whyChoose: `Our program emphasizes algorithmic thinking and problem-solving skills. Learn from experienced computer scientists and software developers. The curriculum is designed to prepare students for the rapidly evolving tech industry. Graduates are well-suited for roles in software development, data analysis, and research.`,
    duration: "2 Years",
    entryRequirements: [
      "A Craft Certificate in Computer Studies or related field OR",
      "A Uganda Advanced Certificate of Education (UACE) with at least one principal pass in Mathematics or Physics."
    ],
    syllabus: [
      "Programming Fundamentals",
      "Data Structures and Algorithms",
      "Object-Oriented Programming",
      "Database Systems",
      "Software Engineering",
      "Computer Architecture",
      "Operating Systems",
      "Theory of Computation"
    ],
    careerProspects: [
      "Software Developer",
      "Data Analyst",
      "Web Application Developer",
      "Database Programmer",
      "Software Tester",
      "IT Consultant"
    ],
    awardingBody: "Uganda Business and Technical Examinations Board (UBTEB)",
  },
  {
    title: "National Diploma in Fashion & Garment Design (NDFD)",
    description:
      "Unleash your creativity in fashion and garment design, learning about textiles, pattern making, and fashion marketing.",
    image: "/images/fashion_and_design.jpg",
    category: 'diploma',
    overview: `The National Diploma in Fashion & Garment Design at Kyadondo Technical Institute is a creative program focused on developing skilled fashion designers and garment technologists. Students will explore textile science, pattern making, fashion illustration, and garment construction. Prepare to express your artistic vision in the world of fashion.`,
    whyChoose: `Our program offers hands-on experience in fashion studios with industry-standard equipment. Learn from experienced fashion designers and textile experts. The curriculum is designed to foster creativity and technical skills relevant to the fashion industry. Graduates are prepared for careers in fashion design, garment production, and fashion retail.`,
    duration: "2 Years",
    entryRequirements: [
      "A Craft Certificate in Tailoring or Fashion Design OR",
      "A Uganda Advanced Certificate of Education (UACE) with at least one principal pass in Art or any relevant subject."
    ],
    syllabus: [
      "Fashion Design Principles",
      "Textile Science",
      "Pattern Making",
      "Garment Construction",
      "Fashion Illustration",
      "Fashion Marketing and Merchandising",
      "History of Fashion",
      "Fashion CAD"
    ],
    careerProspects: [
      "Fashion Designer",
      "Garment Technologist",
      "Pattern Maker",
      "Fashion Illustrator",
      "Fashion Merchandiser",
      "Costume Designer"
    ],
    awardingBody: "Uganda Business and Technical Examinations Board (UBTEB)",
  },
  {
    title:
      "National Diploma in Hotel Management & Institutional Catering (NDHIC)",
    description:
      "Launch your hospitality career with a diploma in hotel management and catering, mastering service excellence and culinary arts.",
    image:
      "https://uenr.edu.gh/wp-content/uploads/2021/03/Department-of-Ecotourism.jpg.webp",
    category: 'diploma',
    overview: `The National Diploma in Hotel Management and Institutional Catering at Kyadondo Technical Institute is a comprehensive program designed to equip students with the skills, knowledge, and professionalism required in the dynamic hospitality and catering industry. This diploma blends practical experience with theoretical insights, preparing graduates for rewarding careers in hotel management, food service, and institutional catering.`,
    whyChoose: `Our program emphasizes practical learning through industrial attachments, real-world simulations, and state-of-the-art training facilities. The curriculum is aligned with current industry standards, ensuring students gain relevant and up-to-date skills. Learn from hospitality experts and seasoned professionals in hotel management and catering. Graduates can pursue careers as hotel managers, catering supervisors, restaurant owners, event planners, and more.`,
    duration: "2 Years",
    entryRequirements: [
      "A Craft Certificate in Hotel Management & Catering OR",
      "A Uganda Advanced Certificate of Education (UACE) with at least one principal pass in relevant subjects."
    ],
    syllabus: [
      "Hotel Operations & Management",
      "Food Production & Culinary Arts",
      "Housekeeping & Front Office Management",
      "Hospitality Business Management",
      "Customer Service & Guest Relations",
      "Food Safety & Hygiene",
      "Event Planning & Catering Services",
      "Entrepreneurship in Hospitality"
    ],
    careerProspects: [
      "Hotel Manager",
      "Catering Supervisor",
      "Restaurant Owner",
      "Event Planner",
      "Hospitality Consultant",
      "Airline Catering",
      "Cruise Ship Catering",
      "Hospital Catering",
      "Institutional Catering"
    ],
    awardingBody: "Uganda Business and Technical Examinations Board (UBTEB)",
  },

  {
    title: "NATIONAL DIPLOMA IN TOURISM & HOSPITALITY MANAGEMENT",
    description: `The National Diploma in Tourism & Hospitality Management at Kyadondo Technical Institute is a comprehensive program designed to equip students with the knowledge, skills, and competencies required to excel in the dynamic tourism and hospitality industry. This program integrates practical training with theoretical knowledge, ensuring that graduates are well-prepared for employment or entrepreneurship in the sector.`,
    image: "/images/placeholder.png",
    category: 'diploma',
    overview: `The National Diploma in Tourism & Hospitality Management at Kyadondo Technical Institute is a comprehensive program designed to equip students with the knowledge, skills, and competencies required to excel in the dynamic tourism and hospitality industry. This program integrates practical training with theoretical knowledge, ensuring that graduates are well-prepared for employment or entrepreneurship in the sector.`,
    whyChoose: `Industry-Relevant Curriculum: Covers key areas such as customer service, travel operations, hotel management, tour guiding, and event planning. Practical Training: Hands-on experience through internships, fieldwork, and industry attachments. Expert Faculty: Learn from experienced professionals and educators in tourism and hospitality. Career Opportunities: Graduates can work in hotels, airlines, travel agencies, tourism boards, resorts, and more. Entrepreneurial Skills: Gain knowledge to start and manage your own tourism or hospitality business.`,
    duration: "2 academic years", // Based on "The program is structured into two academic years"
    entryRequirements: [
      "A Craft Certificate in Tourism & Hospitality or a related field from a recognized institution.",
      "A-Level qualification with at least one principal pass in relevant subjects.",
      "Any other equivalent qualification as approved by the regulatory bodies."
    ],
    syllabus: [
      "Year 1: Fundamentals of Tourism & Hospitality, Customer Care, Food & Beverage Service, Tour Operations, Housekeeping & Front Office Operations.",
      "Year 2: Tourism Marketing, Hospitality Law, Sustainable Tourism, Events Management, Financial Management, Research Methods, and Industrial Training."
    ],
    careerProspects: [
      "Hotel & Resort Managers",
      "Tour Operators & Travel Consultants",
      "Event & Conference Managers",
      "Airline Customer Service Representatives",
      "Tourism Entrepreneurs"
    ]
  },
  {
    title: "NATIONAL CERTIFICATE IN TOURISM & HOSPITALITY MANAGEMENT",
    description: `The National Certificate in Tourism & Hospitality Management at Kyadondo Technical Institute is designed to equip students with the essential skills and knowledge required to excel in the dynamic tourism and hospitality industry. This program prepares students for a rewarding career in hotels, travel agencies, tour companies, event management, and other hospitality-related businesses.`,
    image: "/images/placeholder.png",
    category: 'certificate',
    overview: `The National Certificate in Tourism & Hospitality Management at Kyadondo Technical Institute is designed to equip students with the essential skills and knowledge required to excel in the dynamic tourism and hospitality industry. This program prepares students for a rewarding career in hotels, travel agencies, tour companies, event management, and other hospitality-related businesses.`,
    whyChoose: `Comprehensive Training: Gain hands-on experience in customer service, hotel operations, travel management, and hospitality business management. Industry-Relevant Curriculum: Designed in alignment with national standards to meet the evolving needs of the tourism and hospitality industry. Experienced Faculty: Learn from qualified instructors with vast industry experience. Practical Exposure: Engage in internships and real-world hospitality environments to enhance your skills. Career Opportunities: Graduates can work in hotels, airlines, travel agencies, tour companies, and event planning firms.`,
    duration: "2 years", // From "The course runs for two years"
    entryRequirements: [
      "O’ Level Certificate (UCE) with at least 3 credits or equivalent.",
      "Candidates with prior experience in tourism or hospitality may be considered for admission under special arrangements."
    ],
    syllabus: [
      "Introduction to Tourism & Hospitality",
      "Customer Care & Front Office Operations",
      "Food & Beverage Service & Management",
      "Housekeeping & Accommodation Management",
      "Travel & Tour Operations",
      "Events Planning & Management",
      "Entrepreneurship in Hospitality",
      "Health, Safety & Hygiene in Hospitality"
    ],
    careerProspects: [
      "Hotel & Restaurant Supervisors",
      "Travel & Tour Consultants",
      "Front Office Managers",
      "Housekeeping Supervisors",
      "Event Coordinators",
      "Airline & Cruise Staff",
      "Entrepreneurs in Tourism & Hospitality"
    ]
  },

  {
    title: "NATIONAL CERTIFICATE IN FASHION & GARMENT DESIGN",
    description: `The National Certificate in Fashion & Garment Design at Kyadondo Technical Institute is a hands-on, industry-driven program designed to equip students with the technical and creative skills needed to excel in the fashion industry.`,
    image: "/images/placeholder.png",
    category: 'certificate',
    overview: `The National Certificate in Fashion & Garment Design at Kyadondo Technical Institute is a hands-on, industry-driven program designed to equip students with the technical and creative skills needed to excel in the fashion industry. Whether you aspire to become a fashion designer, tailor, or garment production specialist, this course offers the perfect foundation to turn your passion into a thriving career.`,
    whyChoose: `Practical Learning: Gain real-world experience through hands-on training in garment construction, pattern drafting, textile selection, and sewing techniques. Industry-Relevant Skills: Learn from experienced instructors with expertise in fashion design, tailoring, and entrepreneurship. Modern Facilities: Access well-equipped workshops with sewing machines, pattern-making tools, and fashion design software. Career Opportunities: Prepare for various roles in the fashion industry, including clothing production, custom tailoring, boutique management, and fashion entrepreneurship.`,
    duration: "2 years", // From "Course Duration: 2 years"
    entryRequirements: [
      "A minimum of Ordinary Level Certificate (O-Level) or its equivalent.",
      "Passion for fashion, creativity, and a willingness to learn."
    ],
    syllabus: [
      "Fashion Design & Illustration – Learn the fundamentals of sketching, fabric selection, and garment styling.",
      "Pattern Drafting & Cutting – Master the art of creating and adjusting patterns for different body sizes.",
      "Sewing & Garment Construction – Develop expertise in using sewing machines and assembling garments professionally.",
      "Textile Science – Understand different fabrics, their properties, and best uses.",
      "Entrepreneurship & Marketing – Gain business skills to start and manage a successful fashion brand."
    ],
    careerProspects: [
      "Fashion Designer",
      "Tailor/Dressmaker",
      "Garment Production Specialist",
      "Boutique Owner",
      "Costume Designer",
      "Fashion Illustrator"
    ]
  },

  {
    title: "NATIONAL CERTIFICATE IN HOTEL MANAGEMENT & INSTITUTIONAL CATERING",
    description: `The National Certificate in Hotel Management and Institutional Catering at Kyadondo Technical Institute is designed to equip students with essential skills in hospitality, food service, and hotel operations. This hands-on program prepares graduates for successful careers in hotels, restaurants, catering businesses, and other hospitality establishments.`,
    image: "https://institutiontoday.com/wp-content/uploads/cwv-webp-images/2023/05/Catering-and-accommodation-course.jpg.webp",
    category: 'certificate',
    overview: `The National Certificate in Hotel Management and Institutional Catering at Kyadondo Technical Institute is designed to equip students with essential skills in hospitality, food service, and hotel operations. This hands-on program prepares graduates for successful careers in hotels, restaurants, catering businesses, and other hospitality establishments.`,
    whyChoose: `Comprehensive Training: Covers both theoretical and practical aspects of hotel management and catering. Industry-Standard Facilities: State-of-the-art kitchens, restaurants, and training hotels for practical learning. Experienced Instructors: Learn from professionals with years of experience in the hospitality sector. Employment Opportunities: Graduates are highly sought after in the hospitality industry, both locally and internationally. Entrepreneurial Skills: Equips students with the knowledge to start and manage their own catering or hospitality businesses.`,
    duration: "Two years",
    entryRequirements: [
      "At least O' Level (UCE) certificate or its equivalent",
      "Uganda community Polytechnic Certificate or its equivalent.",
      "Passion for the hospitality industry."
    ],
    syllabus: [
      "Food and Beverage Production & Service",
      "Housekeeping & Front Office Operations",
      "Nutrition and Dietetics",
      "Culinary Arts & Baking",
      "Event Planning & Catering Management",
      "Hygiene, Safety & Sanitation",
      "Customer Service & Communication Skills",
      "Entrepreneurship and Business Management"
    ],
    careerProspects: [
      "Hotel Managers",
      "Chefs & Kitchen Supervisors",
      "Restaurant & Catering Managers",
      "Hospitality Entrepreneurs",
      "Event & Banquet Coordinators"
    ]
  },



  {
    title: "National Diploma in Cosmetology (NDCos)",
    description:
      "Delve into the world of beauty and wellness with comprehensive training in cosmetology, skincare, and makeup artistry.",
    image: "/images/placeholder.png",

    category: 'diploma',
    overview: `The National Diploma in Cosmetology at Kyadondo Technical Institute offers an artistic and scientific exploration of beauty and wellness. Students will gain skills in hairstyling, skincare, makeup artistry, and salon management. Prepare to enhance beauty and well-being with professional cosmetology skills.`,
    whyChoose: `Our program features a fully equipped cosmetology salon for practical training. Learn from certified cosmetologists and beauty industry professionals. The curriculum is designed to meet the demands of the beauty and wellness industry. Graduates are prepared for careers in salons, spas, beauty clinics, and the fashion industry.`,
    duration: "2 Years",
    entryRequirements: [
      "A Craft Certificate in Hairdressing or Beauty Therapy OR",
      "A Uganda Advanced Certificate of Education (UACE) with at least one principal pass in Biology or any relevant subject."
    ],
    syllabus: [
      "Hairdressing Techniques",
      "Skincare Science",
      "Makeup Artistry",
      "Nail Technology",
      "Salon Management",
      "Cosmetic Chemistry",
      "Anatomy and Physiology",
      "Beauty Therapy Practices"
    ],
    careerProspects: [
      "Cosmetologist",
      "Hairstylist",
      "Makeup Artist",
      "Beauty Therapist",
      "Salon Manager",
      "Cosmetics Consultant"
    ],
    awardingBody: "Uganda Business and Technical Examinations Board (UBTEB)",
  },
  {
    title: "National Diploma in Accounting (NDA)",
    description:
      "Master the principles of accounting, financial reporting, and auditing in this rigorous program designed for aspiring accountants.",
    image: "/images/accounting.jpg",
    category: 'diploma',
    overview: `The National Diploma in Accounting at Kyadondo Technical Institute is a rigorous program for aspiring accounting professionals. Students will master financial accounting, management accounting, auditing, and taxation. Prepare for a career in finance with a solid accounting foundation.`,
    whyChoose: `Our program emphasizes practical accounting skills using industry-standard software and simulations. Learn from certified accountants and financial experts. The curriculum is designed to meet the requirements of professional accounting bodies. Graduates are prepared for roles in accounting firms, corporations, and government agencies.`,
    duration: "2 Years",
    entryRequirements: [
      "A Certificate in Accounting or Business Studies OR",
      "A Uganda Advanced Certificate of Education (UACE) with at least one principal pass in Economics or Mathematics."
    ],
    syllabus: [
      "Financial Accounting",
      "Management Accounting",
      "Cost Accounting",
      "Auditing Principles",
      "Taxation",
      "Business Law",
      "Financial Management",
      "Accounting Information Systems"
    ],
    careerProspects: [
      "Accountant",
      "Auditor",
      "Financial Analyst",
      "Tax Consultant",
      "Bookkeeper",
      "Payroll Specialist"
    ],
    awardingBody: "Uganda Business and Technical Examinations Board (UBTEB)",
  },
  {
    title: "National Diploma in Business Administration (NDBA)",
    description:
      "Develop key business skills in management, marketing, and entrepreneurship to thrive in today's dynamic business environment.",
    image: "/images/business_admin.jpg",
    category: 'diploma',
    overview: `The National Diploma in Business Administration at Kyadondo Technical Institute is a versatile program designed to develop essential business skills. Students will learn about management, marketing, finance, and entrepreneurship. Prepare to lead and innovate in the business world.`,
    whyChoose: `Our program includes case studies, business simulations, and guest lectures from industry leaders. Learn from experienced business professionals and entrepreneurs. The curriculum is designed to prepare students for the challenges of modern business. Graduates are ready for roles in management, marketing, sales, and business operations.`,
    duration: "2 Years",
    entryRequirements: [
      "A Certificate in Business Studies or related field OR",
      "A Uganda Advanced Certificate of Education (UACE) with at least one principal pass in Economics, Commerce, or Entrepreneurship."
    ],
    syllabus: [
      "Principles of Management",
      "Marketing Management",
      "Financial Accounting",
      "Business Law",
      "Human Resource Management",
      "Business Communication",
      "Entrepreneurship",
      "Operations Management"
    ],
    careerProspects: [
      "Business Administrator",
      "Marketing Assistant",
      "Sales Representative",
      "Office Manager",
      "Entrepreneur",
      "Customer Service Manager"
    ],
    awardingBody: "Uganda Business and Technical Examinations Board (UBTEB)",
  },
  {
    title: "National Diploma in Journalism (NDJ)",
    description:
      "Pursue a career in journalism with training in news writing, multimedia reporting, and ethical journalism practices.",
    image: "/images/journalism.jpg",
    category: 'diploma',
    overview: `The National Diploma in Journalism at Kyadondo Technical Institute is an engaging program for aspiring journalists and media professionals. Students will develop skills in news writing, reporting, multimedia journalism, and media ethics. Prepare to inform and influence public opinion with responsible journalism.`,
    whyChoose: `Our program features practical workshops in newsrooms and media production studios. Learn from experienced journalists and media trainers. The curriculum emphasizes ethical journalism and media innovation. Graduates are prepared for roles in newspapers, radio, television, online media, and public relations.`,
    duration: "2 Years",
    entryRequirements: [
      "A Certificate in Mass Communication or Journalism OR",
      "A Uganda Advanced Certificate of Education (UACE) with at least one principal pass in Literature, History, or Economics."
    ],
    syllabus: [
      "News Writing and Reporting",
      "Broadcast Journalism",
      "Photojournalism",
      "Multimedia Journalism",
      "Media Law and Ethics",
      "Public Relations",
      "Digital Journalism",
      "Investigative Journalism"
    ],
    careerProspects: [
      "Journalist",
      "Reporter",
      "News Editor",
      "Broadcast Journalist",
      "Public Relations Officer",
      "Content Writer"
    ],
    awardingBody: "Uganda Business and Technical Examinations Board (UBTEB)",
  },
  {
    title: "Diploma in Nursery Teacher Education (ECDTE)",
    description:
      "Specialize in early childhood development and education, preparing to shape the minds of young learners in nursery settings.",
    image: "/images/nursery.avif",
    category: 'diploma',
    overview: `The Diploma in Nursery Teacher Education (ECDTE) at Kyadondo Technical Institute is a specialized program focused on early childhood education. Students will learn about child development, curriculum planning, and effective teaching methodologies for young children. Prepare to nurture and educate the next generation.`,
    whyChoose: `Our program includes practical teaching experience in nursery and preschool settings. Learn from experienced early childhood educators and child psychologists. The curriculum is designed to meet the standards of early childhood education. Graduates are prepared for roles in nursery schools, preschools, and childcare centers.`,
    duration: "2 Years",
    entryRequirements: [
      "A Certificate in Early Childhood Development Education OR",
      "A Uganda Advanced Certificate of Education (UACE) with at least one principal pass in any relevant subject."
    ],
    syllabus: [
      "Child Development and Psychology",
      "Early Childhood Curriculum",
      "Teaching Methodologies for Early Childhood",
      "Child Health and Nutrition",
      "Classroom Management",
      "Special Needs Education in Early Childhood",
      "Play-Based Learning",
      "Parent and Community Engagement"
    ],
    careerProspects: [
      "Nursery Teacher",
      "Preschool Teacher",
      "Early Childhood Educator",
      "Childcare Center Supervisor",
      "Curriculum Developer (Early Childhood)",
      "Special Education Assistant (Early Childhood)"
    ],
    awardingBody: "Ministry of Education and Sports, Uganda",
  },
  {
    title: "DIPLOMA IN ART & INDUSTRIAL DESIGNS",
    description: `Unlock your creative potential with our Diploma in Art & Industrial Design at Kyadondo Technical Institute. This program is designed to equip students with the artistic skills, technical expertise, and innovative thinking required for a successful career in the creative and industrial design industries.`,
    image: "/images/placeholder.png",
    category: 'diploma',
    overview: `The Diploma in Art & Industrial Design at Kyadondo Technical Institute is designed to equip students with the artistic skills, technical expertise, and innovative thinking required for a successful career in the creative and industrial design industries. This program integrates practical training with theoretical knowledge, ensuring that graduates are well-prepared for employment or entrepreneurship in the sector.`,
    whyChoose: `Comprehensive Curriculum: Covers fine arts, graphic design, product design, interior design, and industrial modeling. Hands-on Training: Learn through practical projects, workshops, and real-world applications. State-of-the-Art Facilities: Access to modern design studios, computer labs, and industrial equipment. Experienced Faculty: Learn from industry professionals with extensive expertise in art and design. Career Opportunities: Graduates can pursue careers in advertising, architecture, manufacturing, fashion, and more.`,
    duration: "2 years full-time study",
    entryRequirements: [
      "Uganda Certificate of Education (UCE) with at least 5 passes.",
      "Uganda Advanced Certificate of Education (UACE) with at least one principal pass.",
      "Certificate in a related field from a recognized institution (for those upgrading)."
    ],
    syllabus: [
      "Drawing & Painting – Master artistic fundamentals and creative expression.",
      "Graphic Design & Multimedia – Develop digital design skills using industry-standard software.",
      "Product & Industrial Design – Learn to conceptualize and create functional designs.",
      "Interior & Exhibition Design – Explore spatial design for homes, offices, and galleries.",
      "Ceramics & Sculpture – Gain hands-on experience in 3D artistic creations.",
      "Entrepreneurship & Business Skills – Understand how to market and monetize your designs."
    ],
    careerProspects: [
      "Graphic designers",
      "Industrial designers",
      "Interior designers",
      "Product developers",
      "Art educators",
      "Exhibition planners"
    ],
  },


  {
    title: "National Certificate in Electrical Installation Systems (NCES)",
    description:
      "Acquire practical skills in electrical installations, focusing on safety, wiring standards, and electrical systems design.",
    image:
      "https://douglaselectric.us/wp-content/uploads/2022/07/electrical-preventative-maintenance.jpg",
    category: 'certificate',
    overview: `The National Certificate in Electrical Installation Systems (NCES) at Kyadondo Technical Institute provides focused practical training in electrical installations. Students will learn about wiring, electrical safety standards, and system design for residential and commercial buildings. Prepare to become a certified electrical installer.`,
    whyChoose: `Our program features hands-on workshops in electrical installation labs. Learn from certified electrical technicians and instructors. The curriculum is designed to meet national electrical safety standards. Graduates are prepared for entry-level positions in electrical contracting and maintenance.`,
    duration: "1 Year",
    entryRequirements: [
      "Uganda Certificate of Education (UCE) with passes in Mathematics and Physics OR",
      "Equivalent qualifications assessed by the Admissions Committee."
    ],
    syllabus: [
      "Basic Electrical Theory",
      "Electrical Wiring and Cables",
      "Electrical Installation Tools and Equipment",
      "Electrical Safety Standards",
      "Residential Electrical Installations",
      "Commercial Electrical Installations",
      "Electrical Drawings and Diagrams",
      "Testing and Commissioning of Electrical Systems"
    ],
    careerProspects: [
      "Electrical Installer",
      "Electrician's Assistant",
      "Electrical Maintenance Technician",
      "Wiring Technician",
      "Electrical Systems Tester",
      "Electrical Apprentice"
    ],
    awardingBody: "Uganda Business and Technical Examinations Board (UBTEB)",
  },
  {
    title: "National Certificate in Welding & Metal Fabrication (NCWMF)",
    description:
      "Learn advanced welding and fabrication techniques to build and repair structures and components in various industries.",
    image:
      "https://www.fvtc.edu/Portals/0/POSImages/Programs/metal-fabrication-welding-lg.jpg",
    category: 'certificate',
    overview: `The National Certificate in Welding & Metal Fabrication (NCWMF) at Kyadondo Technical Institute provides advanced training in welding and metal fabrication processes. Students will learn various welding techniques, metal cutting, and fabrication methods. Prepare to become a certified welder and metal fabricator.`,
    whyChoose: `Our program features a state-of-the-art welding workshop equipped with modern welding machines and tools. Learn from certified welding instructors and experienced fabricators. The curriculum is designed to meet industry standards for welding and fabrication. Graduates are prepared for roles in manufacturing, construction, and repair industries.`,
    duration: "1 Year",
    entryRequirements: [
      "Uganda Certificate of Education (UCE) with passes in Science and Mathematics OR",
      "Equivalent qualifications assessed by the Admissions Committee."
    ],
    syllabus: [
      "Basic Welding Processes (SMAW, GMAW, GTAW)",
      "Advanced Welding Techniques",
      "Metal Cutting and Forming",
      "Blueprint Reading for Welding",
      "Welding Metallurgy",
      "Fabrication and Assembly",
      "Welding Inspection and Quality Control",
      "Welding Safety and Health"
    ],
    careerProspects: [
      "Welder",
      "Metal Fabricator",
      "Welding Technician",
      "Fabrication Technician",
      "Welding Inspector",
      "Maintenance Welder"
    ],
    awardingBody: "Uganda Business and Technical Examinations Board (UBTEB)",
  },
  {
    title: "National Certificate in Automotive Mechanics (NCAM)",
    description:
      "Become a certified Automotive Mechanic with hands-on training in diagnostics, repair, and modern vehicle systems.",
    image:
      "https://mechanicsforafrica.com/wp-content/uploads/2020/06/IMG_9134-copy-1201x800.jpg", // Reusing a previous image - consider finding a more specific one
    category: 'certificate',
    overview: `The National Certificate in Automotive Mechanics (NCAM) at Kyadondo Technical Institute equips students with the essential skills and knowledge required to diagnose, repair, and maintain modern automotive systems. This hands-on program is designed to meet industry standards and prepare graduates for employment or self-employment in the dynamic automotive sector.`,
    whyChoose: `Intensive hands-on experience with modern automotive technologies. Covers engine systems, vehicle electronics, transmission, and diagnostics. Learn from certified professionals with real-world expertise. Graduates are prepared for careers in garages, service centers, and vehicle manufacturing. Gain skills to start and manage an automotive repair business.`,
    duration: "2 Years", // Note: Text says "Two years (including industrial training)" but previous certificate courses were 1 year - please verify duration.
    entryRequirements: [
      "O’ Level Certificate (UCE), Uganda community Polytechnic Certificate or its equivalent",
      "Passion for automotive technology and a willingness to learn"
    ],
    syllabus: [
      "Automotive Engine Technology - Engine construction, operation, and maintenance.",
      "Transmission & Drivetrain Systems - Gearboxes, clutches, and differential systems.",
      "Vehicle Electrical & Electronics - Wiring, ignition systems, and modern vehicle diagnostics.",
      "Brake & Suspension Systems - Safety-critical components and repair techniques.",
      "Fuel & Emission Systems - Fuel injection, carburetors, and emission control technologies.",
      "Automotive Workshop Practice - Hands-on vehicle maintenance and repair procedures.",
      "Entrepreneurship & Business Skills - How to set up and run an automotive business."
    ],
    careerProspects: [
      "Automotive Mechanic",
      "Vehicle Service Technician",
      "Workshop Supervisor",
      "Auto Electrical Specialist",
      "Automotive Entrepreneur"
    ],
    awardingBody: "Uganda Business and Technical Examinations Board (UBTEB)",
  },
  {
    title: "National Certificate in Plumbing & Pipe Fittings (NCPPF)",
    description:
      "Become a certified Plumber & Pipe Fitter! Build essential skills in plumbing, sanitation, and water systems.",
    image: "/images/placeholder.png",


    category: 'certificate',
    overview: `The National Certificate in Plumbing & Pipe Fittings at Kyadondo Technical Institute is a hands-on vocational program designed to equip students with essential skills in plumbing systems, water supply, drainage, sanitation, and pipe installations. This course prepares learners for careers in residential, commercial, and industrial plumbing, ensuring they meet industry standards and regulatory requirements.`,
    whyChoose: `Gain hands-on experience in pipe installation, maintenance, and repair. Learn the latest techniques in plumbing, water conservation, and safety standards. Earn a certified and recognized qualification that meets national and international plumbing standards. Explore employment and entrepreneurship opportunities in construction, utilities, or starting your own business.`,
    duration: "2 Years",
    entryRequirements: [
      "Completion of Ordinary Level (O-Level) Education, a Polytechnic certificate or its equivalent.",
      "Passion for technical and hands-on work.",
      "Basic proficiency in Mathematics and Physics is an added advantage."
    ],
    syllabus: [
      "Introduction to Plumbing Systems",
      "Water Supply & Distribution",
      "Sanitation & Waste Management",
      "Drainage & Sewerage Systems",
      "Pipe Installation, Repair & Maintenance",
      "Reading & Interpreting Plumbing Blueprints",
      "Workplace Safety & Environmental Considerations",
      "Business Skills for Plumbers"
    ],
    careerProspects: [
      "Plumber",
      "Pipe Fitter",
      "Drainage Technician",
      "Plumbing Inspector",
      "Join construction, water supply, and maintenance industries.",
      "Pursue further studies in advanced plumbing or civil engineering.",
      "Start their own plumbing business.",
    ],
    awardingBody: "Uganda Business and Technical Examinations Board (UBTEB)",
  },
  {
    title: "National Certificate in ICT (NCICT)",
    description:
      "Get hands-on experience with the latest in information and communication technologies, from networking to cybersecurity.",
    image:
      "https://findit-resources.s3.amazonaws.com/forums/1656996397348.jpg",
    category: 'certificate',
    overview: `The National Certificate in ICT (NCICT) at Kyadondo Technical Institute offers practical skills and knowledge in Information and Communication Technologies. Students will gain hands-on experience in networking, computer hardware, and basic software applications. Prepare for entry-level IT support roles.`,
    whyChoose: `Our program includes practical labs with computers and networking equipment. Learn from IT instructors and support professionals. The curriculum is designed to provide foundational IT skills for various industries. Graduates are prepared for roles in IT support, help desk services, and data entry.`,
    duration: "1 Year",
    entryRequirements: [
      "Uganda Certificate of Education (UCE) with passes in English and Mathematics OR",
      "Equivalent qualifications assessed by the Admissions Committee."
    ],
    syllabus: [
      "Basic Computer Hardware",
      "Operating Systems Basics",
      "Networking Fundamentals",
      "Internet and Web Technologies",
      "Introduction to Cybersecurity",
      "Data Entry and Processing",
      "IT Support Fundamentals",
      "Office Productivity Software"
    ],
    careerProspects: [
      "IT Support Assistant",
      "Help Desk Technician",
      "Data Entry Clerk",
      "Computer Operator",
      "Network Assistant",
      "Technical Support Staff"
    ],
    awardingBody: "Uganda Business and Technical Examinations Board (UBTEB)",
  },

  {
    title: "National Certificate in Building & Construction (NCBC)",
    description:
      "Gain foundational knowledge in construction techniques, project management, and sustainable building practices.",
    image:
      "https://www.iiftbangalore.com/img/sections/courses/home-page-courses/PG-Diploma-fashion-design-boutique-management.webp",
    category: 'certificate',
    overview: `The National Certificate in Building & Construction (NCBC) at Kyadondo Technical Institute introduces students to the fundamental aspects of building and construction. Students will learn about construction materials, basic building techniques, and site management. Prepare to start a career in the building and construction industry.`,
    whyChoose: `Our program features hands-on training in construction workshops and simulated building sites. Learn from experienced building instructors and construction professionals. The curriculum is designed to provide essential knowledge for the construction industry. Graduates are prepared for entry-level roles in construction sites, building maintenance, and related fields.`,
    duration: "1 Year",
    entryRequirements: [
      "Uganda Certificate of Education (UCE) with passes in Technical Subjects or Science OR",
      "Equivalent qualifications assessed by the Admissions Committee."
    ],
    syllabus: [
      "Construction Materials",
      "Basic Building Construction Techniques",
      "Site Management Fundamentals",
      "Construction Drawings and Blueprints",
      "Building Codes and Regulations",
      "Construction Safety Practices",
      "Introduction to Sustainable Building",
      "Construction Tools and Equipment"
    ],
    careerProspects: [
      "Construction Worker",
      "Building Site Assistant",
      "Construction Material Handler",
      "Building Maintenance Assistant",
      "Site Safety Assistant",
      "Construction Laborer"
    ],
    awardingBody: "Uganda Business and Technical Examinations Board (UBTEB)",
  },

  {
    title: "National Certificate in Hair Dressing Technology (NCHDT)",
    description:
      "Develop professional skills in hair styling, cutting, coloring, and salon management.",
    image:
      "https://www.tandikainternational.com/wp-content/uploads/elementor/thumbs/HAIR-pghkf90jz7axe2030ggcm6rkdv2nv0n5umt8q0tjw8.webp",
    category: 'certificate',
    overview: `The National Certificate in Hair Dressing Technology (NCHDT) at Kyadondo Technical Institute offers basic professional skills in hairdressing. Students will learn hairstyling techniques, basic hair cutting, hair coloring fundamentals, and introductory salon practices. Prepare to begin your career as a hairdressing professional.`,
    whyChoose: `Our program features practical training in a salon-simulated environment. Learn from experienced hairdressing instructors and salon professionals. The curriculum is designed to provide essential skills for the hairdressing industry. Graduates are prepared for entry-level roles in hair salons and beauty parlors.`,
    duration: "1 Year",
    entryRequirements: [
      "Uganda Certificate of Education (UCE) OR",
      "Equivalent qualifications assessed by the Admissions Committee."
    ],
    syllabus: [
      "Basic Hair Cutting Techniques",
      "Hair Styling Fundamentals",
      "Hair Coloring Basics",
      "Hair and Scalp Care",
      "Salon Hygiene and Safety",
      "Client Consultation",
      "Introduction to Salon Management",
      "Hairdressing Tools and Equipment"
    ],
    careerProspects: [
      "Hairdresser",
      "Hair Stylist Assistant",
      "Salon Assistant",
      "Barber Assistant",
      "Hair Care Product Retailer",
      "Entry-Level Salon Staff"
    ],
    awardingBody: "Uganda Business and Technical Examinations Board (UBTEB)",
  },
  {
    title: "National Certificate in Accounting (NCBA)",
    description:
      "Learn accounting fundamentals, financial analysis, and fiscal management to support businesses in financial decision-making.",
    image:
      "https://images.squarespace-cdn.com/content/v1/54023cabe4b00e19c7e8ac4a/1593709351146-TJ0VFSC8690B6A95AM1H/image-asset.jpeg",
    category: 'certificate',
    overview: `The National Certificate in Accounting (NCBA) at Kyadondo Technical Institute provides a foundation in accounting principles and financial management. Students will learn basic accounting procedures, financial record-keeping, and business finance concepts. Prepare for entry-level accounting and finance roles.`,
    whyChoose: `Our program features practical exercises in accounting software and financial data analysis. Learn from experienced accounting instructors and finance professionals. The curriculum is designed to provide essential accounting skills for various business sectors. Graduates are prepared for roles in bookkeeping, accounts assistance, and financial administration.`,
    duration: "1 Year",
    entryRequirements: [
      "Uganda Certificate of Education (UCE) with passes in Mathematics and English OR",
      "Equivalent qualifications assessed by the Admissions Committee."
    ],
    syllabus: [
      "Basic Accounting Principles",
      "Financial Record Keeping",
      "Introduction to Cost Accounting",
      "Business Mathematics",
      "Business Communication",
      "Computerized Accounting Systems",
      "Payroll Accounting",
      "Financial Statements Basics"
    ],
    careerProspects: [
      "Bookkeeper",
      "Accounts Assistant",
      "Payroll Clerk",
      "Invoice Clerk",
      "Data Entry Accountant",
      "Junior Accounting Staff"
    ],
    awardingBody: "Uganda Business and Technical Examinations Board (UBTEB)",
  },
  {
    title: "National Certificate in Business Administration (NCBA)",
    description:
      "Get a broad understanding of business operations, from entrepreneurship to business strategy and organizational behavior.",
    image:
      "https://static.vecteezy.com/system/resources/thumbnails/023/882/076/small/check-display-with-blue-folders-documents-and-media-content-office-clerk-or-employee-search-and-index-files-file-management-data-storage-file-search-illustration-vector.jpg",
    category: 'certificate',
    overview: `The National Certificate in Business Administration (NCBA) at Kyadondo Technical Institute offers a broad introduction to business management and administration. Students will learn basic management principles, marketing basics, and office administration skills. Prepare to start a career in business support and administration.`,
    whyChoose: `Our program includes practical exercises in business planning and office simulations. Learn from experienced business instructors and management professionals. The curriculum is designed to provide essential skills for business administration. Graduates are prepared for entry-level roles in office administration, customer service, and sales support.`,
    duration: "1 Year",
    entryRequirements: [
      "Uganda Certificate of Education (UCE) OR",
      "Equivalent qualifications assessed by the Admissions Committee."
    ],
    syllabus: [
      "Principles of Business Management",
      "Introduction to Marketing",
      "Office Administration Skills",
      "Business Communication",
      "Basic Economics",
      "Entrepreneurship Fundamentals",
      "Customer Service Skills",
      "Business Ethics"
    ],
    careerProspects: [
      "Office Administrator",
      "Administrative Assistant",
      "Customer Service Representative",
      "Sales Assistant",
      "Business Support Staff",
      "Entry-Level Management Trainee"
    ],
    awardingBody: "Uganda Business and Technical Examinations Board (UBTEB)",
  },
  {
    title: "National Certificate in Journalism (NCJ)",
    description:
      "Hone your reporting, writing, and multimedia storytelling skills to make an impact in the media industry.",
    image:
      "https://old.smc.edu.ng/wp-content/uploads/2013/07/media-class.png",
    category: 'certificate',
    overview: `The National Certificate in Journalism (NCJ) at Kyadondo Technical Institute provides foundational skills in journalism and media communication. Students will learn basic news writing, reporting techniques, and introductory multimedia skills. Prepare to begin a career in journalism and media.`,
    whyChoose: `Our program features practical workshops in writing and basic media production. Learn from experienced journalism instructors and media professionals. The curriculum is designed to provide essential skills for entry-level journalism roles. Graduates are prepared for roles in community newspapers, radio stations, and online media platforms.`,
    duration: "1 Year",
    entryRequirements: [
      "Uganda Certificate of Education (UCE) with a pass in English Language OR",
      "Equivalent qualifications assessed by the Admissions Committee."
    ],
    syllabus: [
      "Basic News Writing",
      "Reporting Techniques",
      "Introduction to Broadcast Journalism",
      "Photojournalism Basics",
      "Media Ethics and Responsibility",
      "Interviewing Skills",
      "Community Journalism",
      "Digital Media Introduction"
    ],
    careerProspects: [
      "Journalism Trainee",
      "Reporter Assistant",
      "News Writer (Entry-Level)",
      "Community Journalist",
      "Media Production Assistant",
      "Content Contributor"
    ],
    awardingBody: "Uganda Business and Technical Examinations Board (UBTEB)",
  },

  {
    title: "Certificate in Nursery Teacher Education (ECDTE)",
    description:
      "Specialize in early childhood education techniques, fostering development and learning in young children.",
    image: "/images/teacher.jpg",
    category: 'certificate',
    overview: `The Certificate in Nursery Teacher Education (ECDTE) at Kyadondo Technical Institute offers specialized skills in early childhood care and education. Students will learn child care basics, early learning methodologies, and classroom support techniques for young children. Prepare to work as a nursery teacher assistant or childcare provider.`,
    whyChoose: `Our program includes practical classroom experience in nursery school settings. Learn from experienced early childhood educators and trainers. The curriculum is designed to provide foundational skills for early childhood education. Graduates are prepared for entry-level roles in nursery schools, daycare centers, and early learning programs.`,
    duration: "1 Year",
    entryRequirements: [
      "Uganda Certificate of Education (UCE) OR",
      "Equivalent qualifications assessed by the Admissions Committee."
    ],
    syllabus: [
      "Child Care Basics",
      "Early Childhood Development",
      "Nursery Teaching Methods",
      "Child Health and Safety",
      "Classroom Support Techniques",
      "Play and Learning Activities",
      "Child Observation and Assessment",
      "Communication with Young Children"
    ],
    careerProspects: [
      "Nursery Teacher Assistant",
      "Childcare Provider",
      "Daycare Assistant",
      "Early Learning Program Aide",
      "Classroom Support Staff (Early Childhood)",
      "Playgroup Facilitator"
    ],
    awardingBody: "Ministry of Education and Sports, Uganda",
  },
  {
    title: "Certificate in Art & Industrial Design (AID)",
    description:
      "Unlock your creative potential with courses in art theory, design principles, and hands-on design projects.",
    image:
      "https://blogassets.leverageedu.com/blog/wp-content/uploads/2019/11/23171928/Product-Design-Courses.png",
    category: 'certificate',
    overview: `The Certificate in Art & Industrial Design (AID) at Kyadondo Technical Institute is an introductory program to art and design principles. Students will explore basic art techniques, fundamental design concepts, and hands-on art projects. Prepare to unlock your creative potential in art and design.`,
    whyChoose: `Our program features studio-based practice in drawing and basic design. Learn from experienced art instructors and designers. The curriculum is designed to provide foundational skills in art and design. Graduates are prepared for entry-level roles in art studios, craft workshops, and design-related businesses.`,
    duration: "1 Year",
    entryRequirements: [
      "Uganda Certificate of Education (UCE) OR",
      "Equivalent qualifications assessed by the Admissions Committee."
    ],
    syllabus: [
      "Basic Drawing Skills",
      "Introduction to Design Principles",
      "Color Theory",
      "2D Design",
      "3D Design Basics",
      "Art History Introduction",
      "Creative Thinking Techniques",
      "Portfolio Development Basics"
    ],
    careerProspects: [
      "Art Studio Assistant",
      "Design Assistant (Entry-Level)",
      "Craft Workshop Helper",
      "Art Retail Assistant",
      "Design Trainee",
      "Freelance Artist (Beginner)"
    ],
    awardingBody: "Kyadondo Technical Institute",
  }
];

const sortedCourses = courses.sort((a, b) => {
  if (a.category === b.category) return 0;
  return a.category === 'diploma' ? -1 : 1;
});

export default sortedCourses;
