import React, { useState, useEffect } from 'react';
import Header from '../components/header';
import galleryData from '../data/gallery';
import { useParams } from 'react-router-dom';

export default function Gallery() {
  const { category } = useParams();
  const [activeCategory, setActiveCategory] = useState(category.toLowerCase() || 'entertainment');
  const [modalImage, setModalImage] = useState(null);
  const [modalIndex, setModalIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  // Simulate loading of images
  useEffect(() => {
    setIsLoading(true);
    // Simulate API fetch delay
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 800);
    
    return () => clearTimeout(timer);
  }, [activeCategory]);

  const handleCategoryChange = (category) => {
    setActiveCategory(category);
  };

  const openModal = (image) => {
    const imageIndex = currentCategory.images.findIndex(img => img.id === image.id);
    setModalIndex(imageIndex);
    setModalImage(image);
    document.body.style.overflow = 'hidden';
  };

  const closeModal = () => {
    setModalImage(null);
    document.body.style.overflow = 'auto';
  };
  
  const nextImage = (e) => {
    e.stopPropagation();
    const newIndex = (modalIndex + 1) % currentCategory.images.length;
    setModalIndex(newIndex);
    setModalImage(currentCategory.images[newIndex]);
  };
  
  const prevImage = (e) => {
    e.stopPropagation();
    const newIndex = (modalIndex - 1 + currentCategory.images.length) % currentCategory.images.length;
    setModalIndex(newIndex);
    setModalImage(currentCategory.images[newIndex]);
  };

  // Get current category data
  const currentCategory = galleryData[activeCategory];

  return (
    <>
      {/* HEADER */}
      <Header page="gallery" />

      {/* GALLERY HERO SECTION */}
      <section className="gallery-hero">
        <div className="container">
          <h1 className="text-center blue gallery-title">
            OUR <span className="red">GALLERY</span>
          </h1>
          <div className="title-underline"></div>
          <p className="text-center mb-3">Explore our vibrant campus life through images</p>
          
          {/* CATEGORY SELECTOR */}
          <div className="category-selector">
            <div className="container">
              <div className="d-flex justify-content-center flex-wrap">
                {Object.keys(galleryData).map(category => (
                  <button
                    key={category}
                    className={`btn rounded-pill px-4 mx-2 mb-2 ${activeCategory === category ? 'btn-success' : 'btn-outline-dark'}`}
                    onClick={() => handleCategoryChange(category)}
                  >
                    {galleryData[category].title}
                  </button>
                ))}
              </div>
            </div>
          </div>
     
        </div>
      </section>

      {/* GALLERY GRID */}
      <section className="gallery-grid">
        <div className="container mb-5">
                 
          {/* CATEGORY DESCRIPTION */}
          <div className="category-description mb-4">
            <h2 className="text-center mb-3">{currentCategory.title}</h2>
            <p className="text-center px-md-5">{currentCategory.description}</p>
          </div>

          {isLoading ? (
            <div className="text-center py-5">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
              <p className="mt-3">Loading {currentCategory.title} gallery...</p>
            </div>
          ) : (
            <div className="row g-4">
              {currentCategory.images.map((image, index) => (
                <div key={image.id} className="col-12 col-sm-6 col-md-4">
                  <div 
                    className={`gallery-item shape-${index % 5}`} 
                    onClick={() => openModal(image)}
                  >
                    <div className="gallery-image-wrapper">
                      <img 
                        src={image.url} 
                        alt={image.title} 
                        className="img-fluid gallery-image"
                        onError={(e) => {
                          e.target.src = "/api/placeholder/600/400"; // Fallback image
                          e.target.alt = "Image not available";
                        }}
                      />
                      <div className="gallery-overlay">
                        <div className="gallery-overlay-content">
                          <h5>{image.title}</h5>
                          <p className="d-none d-md-block">{image.description}</p>
                          <button className="btn btn-sm btn-light rounded-pill">
                            <i className="fa fa-search-plus me-1"></i>View
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="gallery-caption d-block d-md-none mt-2">
                      <h6>{image.title}</h6>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </section>

      {/* IMAGE MODAL */}
      {modalImage && (
        <div className="gallery-modal" onClick={closeModal}>
          <div className="modal-close-btn" onClick={closeModal}>×</div>
          <div className="gallery-modal-content" onClick={(e) => e.stopPropagation()}>
            <div className="modal-nav-btn modal-prev-btn" onClick={prevImage}>
              <i className="fa fa-chevron-left"></i>
            </div>
            <div className="modal-nav-btn modal-next-btn" onClick={nextImage}>
              <i className="fa fa-chevron-right"></i>
            </div>
            <img 
              src={modalImage.url} 
              alt={modalImage.title} 
              className="img-fluid"
              onError={(e) => {
                e.target.src = "/api/placeholder/800/600";
                e.target.alt = "Image not available";
              }}
            />
            <div className="gallery-modal-caption mt-3">
              <h4>{modalImage.title}</h4>
              <p>{modalImage.description}</p>
              <div className="modal-counter">
                {modalIndex + 1} / {currentCategory.images.length}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}