import { useEffect } from "react";
import Footer from "../components/footer";
import Header from "../components/header";
import Slider from "../components/slider";

export default function Homepage() {
  return (
    <div>
      <Header page={"home"} />

      <Slider />

      <div>
        {/* about  */}
        <div id="about" className="about mt-3 mt-md-5">
          <div className="container">
            <div className="about-box">
              <h2 className="red pt-4">
                Welcome To&nbsp;
                <strong className="blue">
                  Kyadondo Technical Institute (KTI)
                </strong>
              </h2>
              <p className="text-justify py-3 py-md-4">
                Welcome to Kyadondo Technical Institute (KTI), where we empower
                the next generation of innovators, technicians, and leaders. At
                KTI, we believe in providing a robust technical education that
                combines rigorous academic study with essential hands-on
                experience. Our programs are designed to meet the evolving needs
                of the industry, ensuring our graduates are not just job-ready
                but are equipped to drive change and shape the future. KTI
                offers a diverse range of courses in engineering, technology,
                and applied sciences, all taught by a dedicated faculty with
                real-world expertise. Our students engage in various
                extracurricular activities, community service projects, and
                leadership development programs, all aimed at nurturing
                well-rounded individuals who are ready to make a positive impact
                in their communities and beyond. Join us at Kyadondo Technical
                Institute and embark on a journey of discovery, learning, and
                personal growth. Shape your future in an environment that values
                tradition yet embraces innovation.
              </p>
            </div>
          </div>
        </div>

        <hr />

        {/* DIRECTOR'S MESSAGE */}
        <div id="about" className="about mt-1">
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="about-box">
                  <h3 className="red uppercase">
                    <strong>
                      {" "}
                      <span className="blue">Message from the&nbsp; </span>
                      Founding Director
                    </strong>
                  </h3>
                  <p className="text-justify py-3 py-md-4">
                    Empowering the World Through Technical Education.
                    <br />
                    In today's rapidly evolving world, technical education
                    stands as the backbone of innovation, economic growth, and
                    sustainable development. At Kyadondo Technical Institute, we
                    believe that empowering individuals with practical skills
                    and industry-relevant knowledge is the key to unlocking
                    boundless opportunities.
                    <br />
                    <br />
                    Technical and vocational education is not just about
                    acquiring skills—it is about shaping futures, transforming
                    communities, and driving nations toward prosperity. The
                    world needs problem solvers, innovators, and skilled
                    professionals who can bridge the gap between theory and
                    practice. That is why we are committed to nurturing talent,
                    fostering creativity, and ensuring that every learner who
                    walks through our doors emerges as a confident, competent,
                    and self-reliant individual.
                    <br />
                    <br />
                    Through our efforts, we have seen firsthand how technical
                    education changes lives—how it turns dreams into reality and
                    provides a pathway to financial independence. However, this
                    mission is bigger than any one institution; it requires
                    collaboration, investment, and a global commitment to making
                    education accessible to all.
                    <br />
                    <br />
                    I invite governments, industries, and education stakeholders
                    to join us in championing a future where technical education
                    is not just an option, but a priority. Together, we can
                    equip the next generation with the skills they need to
                    build, innovate, and lead in a world that is constantly
                    evolving.
                    <br />
                    <br />
                    At Kyadondo Technical Institute, we do not just educate we
                    empower. And through empowerment, we build a better world.
                    <br /> <br />
                    <span className="name-title">Mr. Sserwanja Asadu</span>
                    <br />
                    <span className="title">-Founding Director</span>
                    <br />
                    <span className="title">
                      Kyadondo Technical Institute-Busaabala Kampala
                    </span>
                  </p>

                  <a href="/about">Learn More</a>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="about-box">
                  <figure>
                    <img src="images/director.png" alt="..." />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* our */}
        <div id="important" className="important">
          <div className="container">
            <div className="titlepage">
              <hr />

              <h2 className="red">
                Some <strong className="blue">Important Facts</strong>
              </h2>
            </div>
          </div>
          <div className="important_bg">
            <div className="container">
              <div className="row">
                <div className="col col-xs-12">
                  <div className="important_box">
                    <h3>1000+</h3>
                    <span>Students</span>
                  </div>
                </div>

                <div className="col col-xs-12">
                  <div className="important_box">
                    <h3>200+</h3>
                    <span>Faculty</span>
                  </div>
                </div>
                <div className="col col-xs-12">
                  <div className="important_box">
                    <h3>20+</h3>
                    <span>Programs</span>
                  </div>
                </div>

                <div className="col col-xs-12">
                  <div className="important_box">
                    <h3>10+</h3>
                    <span>Workshops</span>
                  </div>
                </div>

                <div className="col col-xs-12">
                  <div className="important_box">
                    <h3>12+</h3>
                    <span>Industry Partnerships</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* end our */}
        {/* Courses */}
        <div id="courses" className="Courses">
          <div className="container-fluid padding_left3">
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="box_bg">
                  <div className="box_bg_img">
                    <div className="row">
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <div className="box_my">
                          <figure>
                            <img src="images/gallery/academics/IMG-20250326-WA0038.jpg" />
                          </figure>
                          <div className="overlay">
                            <h4 className="text-white py-1">Engineering</h4>
                            <p>
                              Our engineering courses deliver core principles
                              and hands-on experience with advanced engineering
                              solutions.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <div className="box_my">
                          <figure>
                            <img src="images/cs.webp" />
                          </figure>
                          <div className="overlay">
                            <h4 className="text-white py-1">
                              Computer Sciences
                            </h4>

                            <p>
                              Our programs provide knowledge and in-depth
                              insights into the latest technologies and their
                              practical uses.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <div className="box_my">
                          <figure>
                            <img src="images/accounting.webp" />
                          </figure>
                          <div className="overlay">
                            <h4 className="text-white py-1">
                              Accounting & Finance
                            </h4>
                            <p>
                              Our accounting and finance programs focus on
                              essential financial concepts and real-world
                              financial practices.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <div className="box_my">
                          <figure>
                            <img src="images/fashion.jpg" />
                          </figure>
                          <div className="overlay">
                            <h5 className="text-white py-1">
                              Fashion & Design
                            </h5>
                            <p>
                              Our fashion and design courses blend creative
                              theory with practical skill in contemporary style
                              and trends.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 border_right">
                <div className="box_text px-3 px-md-0">
                  <div className="titlepage">
                    <h2 className="red">
                      Our <strong className="blue"> Courses</strong>
                    </h2>
                  </div>
                  <p>
                    Our educational offerings are designed to set you on the
                    path to professional success. Our diploma and certificate
                    courses cover key industries such as engineering,
                    technology, design, hospitality, business, and more, all
                    tailored to meet today's job market demands. Our courses
                    blend practical skills with essential knowledge, ensuring
                    you not only understand the theory but can also apply it in
                    real-world situations. With dedicated instructors and modern
                    facilities, we provide the tools and support necessary for
                    learning and innovation. Whether you're starting a new
                    career or advancing in your current one, KTI offers a
                    learning environment that fosters growth, creativity, and
                    the expertise needed to thrive in your chosen field. Join us
                    to build a solid foundation for your future.
                  </p>
                  <a href="/courses">Learn More</a>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* WHY CHOOSE KTI */}
        <div className="make">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="titlepage">
                  <h2>
                    Why Choose
                    <strong className="white_colo"> KTI?</strong>
                  </h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-8 col-sm-12 text-white choose_us">
                <p>
                  <strong className="yellow">
                    Why Parents Should Focus on Vocational Training After UCE or
                    UACE
                  </strong>{" "}
                  <br />
                  In today’s fast-changing world, acquiring practical skills is
                  the key to securing a stable and successful future. At
                  Kyadondo Technical Institute Busaabala-Kampala, we equip
                  students with hands-on training that guarantees employment,
                  entrepreneurship, and career growth. Here’s why Kyadondo
                  Technical Institute is the best choice for your child:
                </p>
                <hr />
                <p>
                  <span className="yellow">1. Market-Driven Courses:</span>
                  We offer a wide range of technical and vocational programs
                  tailored to meet industry demands. Whether your child is
                  passionate about engineering, construction, ICT, or business,
                  we provide the necessary skills to thrive in today’s
                  competitive job market.
                </p>
                <hr />

                <p>
                  <span className="yellow">
                    2. Affordable and Accessible Education:
                  </span>
                  We believe that quality education should be affordable. Our
                  fees are structured to accommodate students from all
                  backgrounds, with flexible payment options and bursary
                  opportunities available.
                </p>
                <hr />

                <p>
                  <span className="yellow">
                    {" "}
                    3. Hands-On Learning for Job Readiness:
                  </span>
                  Unlike traditional education systems that focus heavily on
                  theory, we emphasize practical training. The state-of-the-art
                  workshops and experienced instructors ensure that students
                  graduate with real-world skills that employers seek.
                </p>
                <hr />

                <p>
                  <span className="yellow">
                    4. Strong Industry Connections & Internship Opportunities:
                  </span>
                  We work closely with industries and businesses to provide
                  internship placements and job linkages. Many of our graduates
                  secure employment immediately after completing their studies.
                </p>
                <hr />

                <p>
                  <span className="yellow">
                    5. Entrepreneurship & Self-Employment Support:
                  </span>
                  Not only do we train students for employment, but we also
                  empower them to start their own businesses. Our
                  entrepreneurship programs equip students with skills to create
                  jobs rather than just seek them.
                </p>
                <hr />
                <p>
                  <span className="yellow">
                    6. A Conducive Learning Environment:
                  </span>
                  Located in the serene area of Busaabala-Kampala, Kyadondo
                  Technical Institute provides a safe and inspiring learning
                  environment with modern facilities, professional instructors,
                  and a strong support system.
                </p>
                <hr />
                <p>
                  <span className="yellow">
                    7. Recognition & Certification:
                  </span>
                  Our programs are recognized by relevant government
                  authorities, ensuring that graduates earn certificates and
                  diplomas that hold value in both local and international job
                  markets. Kyadondo Technical Institute is registered with the
                  Ministry of Education, Science, Technology & Sports.
                </p>
                <hr />

                <a
                  href="https://admissions.kti.ac.ug"
                  className="btn btn-light rounded-pill mt-4 px-5 py-2"
                >
                  ENROLL Today!
                </a>
              </div>
              <div className="col-md-4 col-sm-12">
                <div className="make_img">
                  <figure>
                    <img src="images/why.png" alt="Why Choose KTI Image" />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* learn */}
        <div id="learn" className="learn">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="titlepage">
                  <h2 className="red">
                    Learn <strong className="blue">the Practical way</strong>
                  </h2>
                  <span>
                    Dive into hands-on learning at Kyadondo Technical Institute!
                    Our programs blend theory with practical application,
                    ensuring you gain the skills needed for real-world success.
                    With state-of-the-art facilities and industry-savvy
                    instructors, we're here to turn your passion into a
                    profession. Join us and shape your future, practically!
                  </span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="learn_box">
                  <figure>
                    <iframe
                      width="100%"
                      height="500"
                      src="https://www.youtube.com/embed/C9VrGUiAAb4"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </figure>
                </div>
              </div>
            </div>

            {/* APPLY NOW */}
            <div className="text-center mt-5">
              <button className="btn btn-success rounded-pill px-5 py-2 w-50 apply_btn ">
                APPLY NOW
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
