import React from "react";
import Header from "../components/header";
import { Link } from "react-router-dom";
import courses from "../data/courses";

export default function Courses() {
  const diplomaCourses = courses.filter(
    (course) => course.category === "diploma"
  );
  const certificateCourses = courses.filter(
    (course) => course.category === "certificate"
  );

  return (
    <>
      <Header page="courses" />
      <div className="container mt-4">
        <h1 className="text-center mb-4 py-2 blue ">
          Our <span className="red">Courses</span>
        </h1>

        <ul className="nav nav-tabs nav-fill" id="myTab" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              className="nav-link active tab-btn"
              id="diploma-tab"
              data-bs-toggle="tab"
              data-bs-target="#diploma"
              type="button"
              role="tab"
              aria-controls="diploma"
              aria-selected="true"
            >
              Diploma Courses
            </button>
          </li>

          <li className="nav-item" role="presentation">
            <button
              className="nav-link tab-btn"
              id="certificate-tab"
              data-bs-toggle="tab"
              data-bs-target="#certificate"
              type="button"
              role="tab"
              aria-controls="certificate"
              aria-selected="false"
            >
              Certificate Courses
            </button>
          </li>
        </ul>

        <div className="tab-content" id="myTabContent">
          <div
            className="tab-pane fade show active"
            id="diploma"
            role="tabpanel"
            aria-labelledby="diploma-tab"
          >
            <ul className="courses-bullet-list">
              {diplomaCourses.map((course, index) => (
                <li className="course-list-item" key={index}>
                  <Link to={`/course/${index}`} className="course-link">
                    <span className="bullet-point"></span>
                    <span className="course-bullet-title" style={{ textTransform: 'capitalize' }}>
                      {course.title.toLocaleLowerCase()}
                    </span>
                    <span className="course-arrow">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9 18L15 12L9 6"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div
            className="tab-pane fade"
            id="certificate"
            role="tabpanel"
            aria-labelledby="certificate-tab"
          >

<ul className="courses-bullet-list">
              {certificateCourses.map((course, index) => (
                <li className="course-list-item" key={index}>
                  <Link to={`/course/${diplomaCourses.length + index}`} className="course-link">
                    <span className="bullet-point"></span>
                    <span className="course-bullet-title" style={{ textTransform: 'capitalize' }}>
                      {course.title.toLocaleLowerCase()}
                    </span>
                    <span className="course-arrow">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9 18L15 12L9 6"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </Link>
                </li>
              ))}
            </ul>

          </div>
        </div>
      </div>
    </>
  );
}
