import React, { useEffect, useState } from "react";

export default function Footer() {
  // METHOD TO GET THE CURRENT YEAR
  function getCurrentYear() {
    const date = new Date();
    return date.getFullYear();
  }

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);


  return (
    <footer className={isVisible ? "footer-reveal" : ""}>
      <div className="footer ">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <form className="news">
                <input
                  className="newslatter"
                  placeholder="Enter Your Email Address"
                  type="text"
                  name=" Email"
                />
                <button className="submit">Subscribe</button>
              </form>
            </div>
         
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 ">
              <div className="row">
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 ">
                  <div className="address">
                    <h3 className="yellow">Contact</h3>
                    <ul className="loca">
                      <li>
                        <a href="#">
                          <img src="/icon/loc.png" alt="#" />
                        </a>
                        Busabala-Makindye

                        <br />
                        Kampala Uganda.
                      </li>
                      <li>
                        <a href="mailto:info@kti.ac.ug">
                          <img src="/icon/email.png" alt="#" />
                        </a>
                        info@kti.ac.ug
                      </li>
                      <li>
                        <a href="tel:+256702081313">
                          <img src="/icon/call.png" alt="#" />
                        </a>
                        +256392548317 <br /> +256702081313
                      </li>
                    </ul>
                    <ul className="social_link">
                      <li>
                        <a href="#">
                          <img src="/icon/fb.png" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <img src="/icon/tw.png" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <img src="/icon/lin(2).png" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <img src="/icon/instagram.png" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div className="address">
                    <h3 className="yellow">Courses</h3>
                    <ul className="Menu_footer">
                      <li className="active">
                        <a href="/courses">Engineering</a>
                      </li>
                      <li>
                        <a href="/courses">Information Technology</a>
                      </li>
                      <li>
                        <a href="/courses">Business Administration</a>
                      </li>
                      <li>
                        <a href="/courses">Architecture</a>
                      </li>
                      <li>
                        <a href="/courses">Fashion and Design</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div className="address">
                    <h3 className="yellow">Information</h3>
                    <ul className="Links_footer">
                      <li className="active">
                        <a href="/fees_structure.pdf" target={'_blank'}>Fees Structure</a>
                      </li>
                      <li>
                        <a href="/admission">Rules and Regulations</a>
                      </li>
                      <li>
                        <a href="https://sponsorship.kti.ac.ug">Sponsorship</a>
                      </li>
                      <li>
                        <a href="https://admissions.kti.ac.ug"> Admission</a>
                      </li>
                      <li>
                        <a href="https://portal.kti.ac.ug" target="_blank"> KIMS</a>
                      </li>
                    
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 ">
                    <a href="/" className="d-none d-md-block d-lg-block">
                      <img src="/images/logo2.png" alt="logo"  width={"220px"} />
                    </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-8">
                <p className="text-right copyright_text">
                  Copyright © {getCurrentYear()} Kyadondo Technical Institute.
                  All Rights Reserved.
                </p>
              </div>

              <div className="col-md-4">
                <p className="text-right powered_by" style={{fontSize: "15px"}}>
                  Powered By &nbsp;
                  <a href="https://www.chimp-tech.com" target={"_blank"} className="text-warning ">
                    Chimp Technologies
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
