import React from "react";
import Header from "../components/header";

export default function PrincipalsMessage() {
  return (
    <>
      <Header page={"about"} />

      {/* PRINCIPAL'S MESSAGE HERO */}
      <section className="gallery-hero mb-5">
        <div className="container">
          <h1 className="text-center blue gallery-title">
            PRINCIPAL'S <span className="red">MESSAGE</span>
          </h1>
          <div className="title-underline my-3"></div>
        
        </div>
      </section>

      <div className="container mb-5">
        <div className="row">
          {/* Principal's Image Column */}
          <div className="col-lg-4 mb-4">
            <div className="card border-0 shadow h-100">
              <div className="principal-img overflow-hidden">
                <img 
                  src="/images/principal.jpg" 
                  alt="Mr. Kanaaba Deus, Principal of Kyadondo Technical Institute"
                  className="img-fluid"
                />
              </div>
              <div className="card-body text-center bg-light">
                <h2 className="card-title capitalize blue fw-bold">Mr. Kanaaba Deus</h2>
                <h3 className="card-subtitle mb-2 text-muted">PRINCIPAL</h3>
                <div className="d-flex justify-content-center gap-3 mt-3">
                  <a href="#" className="btn btn-sm btn-outline-primary">
                    <i className="bi bi-envelope-fill me-1"></i> Contact
                  </a>
                  <a href="#" className="btn btn-sm btn-outline-secondary">
                    <i className="bi bi-linkedin me-1"></i> Profile
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/* Message Content Column */}
          <div className="col-lg-8">
            <div className="card border-0 shadow message-card h-100">
              <div className="card-body p-4">
                <div className="message-header mb-4">
                  <h2 className="blue fw-bold mb-3">Message from the Principal</h2>
                  <div className="separator-line"></div>
                </div>
                
                <div className="message-content">
                  <p className="lead fw-bold mb-4 red">
                    I am delighted to welcome you to Kyadondo Technical Institute, a center of excellence in hands-on training and career-focused education.
                  </p>
                  
                  <p className="mb-3">
                    At Kyadondo, we believe that practical skills are the key to unlocking opportunities in today's competitive world, and we are committed to providing high-quality technical and vocational training that meets the evolving needs of industries and entrepreneurs alike.
                  </p>
                  
                  <p className="mb-3">
                    Whether you are a student seeking to gain marketable skills, a professional looking to upgrade your expertise, an employer searching for skilled personnel, or an organization interested in partnerships, Kyadondo Technical Institute is the right place for you. Our dynamic programs cater to a diverse range of learners, from school leavers and job seekers to business owners and corporate professionals.
                  </p>
                  
                  <p className="mb-3">
                    We offer a variety of courses in engineering, construction, automotive mechanics, electrical installation, ICT, business, hospitality, and more—all designed to equip you with practical knowledge and real-world problem-solving abilities. Our experienced instructors, state-of-the-art training facilities, and strong industry partnerships ensure that you receive top-notch education and hands-on experience.
                  </p>
                  
                  <p className="mb-3">
                    At Kyadondo, we don't just train students—we empower future innovators, job creators, and leaders. Our graduates go on to excel in their careers, start their own businesses, and contribute to national and global development.
                  </p>
                  
                  <p className="mb-3">
                    I invite you to explore our website, visit our campus, and discover how Kyadondo Technical Institute can be your gateway to success. Whether you want to build a career, enhance your skills, or collaborate with us, we are here to support your journey every step of the way.
                  </p>
                  
                  <p className="fw-bold blue mb-4">
                    Join us today and turn your passion into a profession!
                  </p>
                  
                  <div className="signature mt-4">
                    <div className="fw-bold fs-5 blue">Mr. Kanaaba Deus</div>
                    <div className="text-muted">Principal, Kyadondo Technical Institute</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Call to Action Section */}
        <div className="row mt-5">
          <div className="col-12">
            <div className="card bg-blue text-white border-0 shadow">
              <div className="card-body p-4 text-center">
                <h3 className="mb-3">Ready to start your journey with us?</h3>
                <p className="mb-4">Discover our programs and take the first step toward your future career.</p>
                <div className="d-flex justify-content-center gap-3">
                  <a href="/courses" className="btn btn-light red fw-bold">
                    Explore Programs
                  </a>
                  <a href="https://admissions.kti.ac.ug" className="btn btn-outline-light fw-bold">
                    Apply Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Additional Styling */}
      <style jsx>{`
        .principal-img {
          height: 300px;
          width: 100%;
        }
        
        .principal-img img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: transform 0.3s ease;
        }
        
        .principal-img:hover img {
          transform: scale(1.05);
        }
        
        .separator-line {
          height: 4px;
          width: 80px;
          background-color: var(--bs-secondary);
          margin-bottom: 20px;
        }
        
        .message-card {
          position: relative;
          overflow: hidden;
        }
      
        
        .signature {
          border-top: 1px solid rgba(0,0,0,0.1);
          padding-top: 15px;
        }
        
     
      `}</style>
    </>
  );
}