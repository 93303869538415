import React from "react";
import { Offcanvas, Navbar, Nav, Container } from "react-bootstrap";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faCaretDown } from "@fortawesome/free-solid-svg-icons";

export default function MobileHeader({ page }) {
  const [show, setShow] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const toggleDropdown = () => setDropdownOpen((prev) => !prev);

  return (
    <>
      <Navbar bg="light" expand={false} className="mobile-header">
        <div className="container-fluid">
          <FontAwesomeIcon
            icon={faBars}
            onClick={handleShow}
            fontSize="25px"
            style={{ cursor: "pointer", paddingLeft: "10px" }}
          />
          <Navbar.Brand href="/">
            <img src="/images/logo.png" alt="..." height={"60px"} />
          </Navbar.Brand>
        </div>
      </Navbar>

      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="start"
        className="custom-offcanvas"
      >
        <Offcanvas.Header
          closeButton
          className="custom-header"
        ></Offcanvas.Header>
        <Offcanvas.Body>
          <Nav className="flex-column">
            <a
              href="/"
              onClick={handleClose}
              className={page === "home" ? "active" : ""}
            >
              Home
            </a>
            <a
              href="/about"
              onClick={handleClose}
              className={page === "about" ? "active" : ""}
            >
              About
            </a>
            <a
              href="/courses"
              onClick={handleClose}
              className={page === "courses" ? "active" : ""}
            >
              Our Courses
            </a>

            <a
              href="/departments"
              onClick={handleClose}
              className={page === "departments" ? "active" : ""}
            >
              Departments
            </a>
            <a
              href="fees_structure.pdf"
              target="_blank"
              onClick={handleClose}
              className={page === "fees" ? "active" : ""} // Adjust "fees" if you have a specific page identifier
            >
              Fees Structure
            </a>

            <a
              href="/admission"
              onClick={handleClose}
              className={page === "admission" ? "active" : ""}
            >
              Admission
            </a>
            <a
              href="/contact"
              onClick={handleClose}
              className={page === "contact" ? "active" : ""}
            >
              Contact
            </a>
            <a href="https://portal.kti.ac.ug" onClick={handleClose}>
              Portal
            </a>
            <a href="https://admissions.kti.ac.ug" className="border-none">
              <button className="btn btn-warning rounded-pill px-4 w-100 mt-3">
                APPLY NOW
              </button>
            </a>
          </Nav>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
