import React from "react";
import staff from "../data/staff";
import Header from "./header";

export default function LeadershipTeam() {
  // Group staff by their hierarchical positions
  const director = staff.find((member) => member.position.includes("Director"));
  const principal = staff.find((member) =>
    member.position.includes("PRINCIPAL")
  );
  const academicRegistrar = staff.find(
    (member) =>
      member.position.includes("Academic Registrar") &&
      !member.position.includes("Deputy")
  );
  const deputyAR = staff.find((member) =>
    member.position.includes("Deputy Academic Registrar")
  );
  const dean = staff.find((member) => member.position.includes("Dean"));
  const bursar = staff.find((member) => member.position.includes("Bursar"));
  const examinationsOfficer = staff.find((member) =>
    member.position.includes("Examinations Officer")
  );
  const systemsAdmin = staff.find((member) =>
    member.position.includes("Systems Administrator")
  );
  const departmentHeads = staff.filter(
    (member) =>
      member.position.includes("HEAD OF DEPARTMENT") ||
      member.position.includes("Head of Department")
  );

  return (
    <>
      <Header page={"staff"} />

      {/* OUR STAFF MEMBERS HERO */}
      <section className="gallery-hero mb-5">
        <div className="container">
          <h1 className="text-center blue gallery-title">
            OUR <span className="red">LEADERSHIP TEAM</span>
          </h1>
          <div className="title-underline"></div>
          <p className="text-center mb-3">
            Meet our team of experienced professionals
          </p>
        </div>
      </section>

      <div className="container">
        <meta
          name="description"
          content="Meet our team at Your Company Name, including Mr. Sserwanja Asadu, our Director & Chairman Board of Trustees, and other experienced professionals."
        />

        {/* Top Management - Director */}
        <h2 className="text-center mt-5 text-bold">TOP MANAGEMENT</h2>
        <div className="title-underline mb-5"></div>

        <div className="row justify-content-center">
          {director && (
            <div className="col-md-4">
              <div className="team">
                <div className="team-img">
                  <img
                    src={director.image}
                    alt={`Photo of ${director.name}, ${director.position}`}
                  />
                </div>
                <div className="team-content">
                  <h2 className="capitalize">{director.name}</h2>
                  <h3 className="capitalize">{director.position}</h3>
                  {director.phone && <p>{director.phone}</p>}
                  {director.email && (
                    <h4 className="lowercase">{director.email}</h4>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Principal */}
        <div className="row justify-content-center mt-4">
          {principal && (
            <div className="col-md-4">
              <div className="team">
                <div className="team-img">
                  <img
                    src={principal.image}
                    alt={`Photo of ${principal.name}, ${principal.position}`}
                  />
                </div>
                <div className="team-content">
                  <h2 className="capitalize">{principal.name}</h2>
                  <h3 className="capitalize">{principal.position}</h3>
                  {principal.phone && <p>{principal.phone}</p>}
                  {principal.email && (
                    <h4 className="lowercase">{principal.email}</h4>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Dean and Bursar (Same Level) */}
        <div className="row justify-content-center mt-4">
          {academicRegistrar && (
            <div className="col-md-4">
              <div className="team">
                <div className="team-img">
                  <img
                    src={academicRegistrar.image}
                    alt={`Photo of ${academicRegistrar.name}, ${academicRegistrar.position}`}
                  />
                </div>
                <div className="team-content">
                  <h2 className="capitalize">{academicRegistrar.name}</h2>
                  <h3 className="capitalize">{academicRegistrar.position}</h3>
                  {academicRegistrar.phone && <p>{academicRegistrar.phone}</p>}
                  {academicRegistrar.email && (
                    <h4 className="lowercase">{academicRegistrar.email}</h4>
                  )}
                </div>
              </div>
            </div>
          )}

          {dean && (
            <div className="col-md-4">
              <div className="team">
                <div className="team-img">
                  <img
                    src={dean.image}
                    alt={`Photo of ${dean.name}, ${dean.position}`}
                  />
                </div>
                <div className="team-content">
                  <h2 className="capitalize">{dean.name}</h2>
                  <h3 className="capitalize">{dean.position}</h3>
                  {dean.phone && <p>{dean.phone}</p>}
                  {dean.email && <h4 className="lowercase">{dean.email}</h4>}
                </div>
              </div>
            </div>
          )}
          {bursar && (
            <div className="col-md-4">
              <div className="team">
                <div className="team-img">
                  <img
                    src={bursar.image}
                    alt={`Photo of ${bursar.name}, ${bursar.position}`}
                  />
                </div>
                <div className="team-content">
                  <h2 className="capitalize">{bursar.name}</h2>
                  <h3 className="capitalize">{bursar.position}</h3>
                  {bursar.phone && <p>{bursar.phone}</p>}
                  {bursar.email && (
                    <h4 className="lowercase">{bursar.email}</h4>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Deputy Academic Registrar */}
        <div className="row justify-content-center mt-4">
          {deputyAR && (
            <div className="col-md-4">
              <div className="team">
                <div className="team-img">
                  <img
                    src={deputyAR.image}
                    alt={`Photo of ${deputyAR.name}, ${deputyAR.position}`}
                  />
                </div>
                <div className="team-content">
                  <h2 className="capitalize">{deputyAR.name}</h2>
                  <h3 className="capitalize">{deputyAR.position}</h3>
                  {deputyAR.phone && <p>{deputyAR.phone}</p>}
                  {deputyAR.email && (
                    <h4 className="lowercase">{deputyAR.email}</h4>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Examinations Officer, Systems Admin & Department Heads (Same Level) */}
        <h2 className="text-center mt-5 text-bold">DEPARTMENTAL LEADERSHIP</h2>
        <div className="title-underline mb-5"></div>


        <div className="row">
          {departmentHeads.map((member) => (
            <div className="col-md-4" key={member.name}>
              <div className="team">
                <div className="team-img">
                  <img
                    src={member.image}
                    alt={`Photo of ${member.name}, ${member.position}`}
                  />
                </div>
                <div className="team-content">
                  <h2 className="capitalize">{member.name}</h2>
                  <h3 className="capitalize">{member.position}</h3>
                  {member.phone && <p>{member.phone}</p>}
                  {member.email && (
                    <h4 className="lowercase">{member.email}</h4>
                  )}
                </div>
              </div>
            </div>
          ))}

          {examinationsOfficer && (
            <div className="col-md-4">
              <div className="team">
                <div className="team-img">
                  <img
                    src={examinationsOfficer.image}
                    alt={`Photo of ${examinationsOfficer.name}, ${examinationsOfficer.position}`}
                  />
                </div>
                <div className="team-content">
                  <h2 className="capitalize">{examinationsOfficer.name}</h2>
                  <h3 className="capitalize">{examinationsOfficer.position}</h3>
                  {examinationsOfficer.phone && (
                    <p>{examinationsOfficer.phone}</p>
                  )}
                  {examinationsOfficer.email && (
                    <h4 className="lowercase">{examinationsOfficer.email}</h4>
                  )}
                </div>
              </div>
            </div>
          )}
          {systemsAdmin && (
            <div className="col-md-4">
              <div className="team">
                <div className="team-img">
                  <img
                    src={systemsAdmin.image}
                    alt={`Photo of ${systemsAdmin.name}, ${systemsAdmin.position}`}
                  />
                </div>
                <div className="team-content">
                  <h2 className="capitalize">{systemsAdmin.name}</h2>
                  <h3 className="capitalize">{systemsAdmin.position}</h3>
                  {systemsAdmin.phone && <p>{systemsAdmin.phone}</p>}
                  {systemsAdmin.email && (
                    <h4 className="lowercase">{systemsAdmin.email}</h4>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
