import lasito from "../assets/staff/lasito.jpg";
import sam from "../assets/staff/sam.jpg";

const staff = [
  // Governing Council
  {
    name: "Mr. Sserwanja Asadu",
    position: "Director & Chairman Board of Trustees", 
    image: "/images/director1.jpg", 
  },
  // Top Management
  {
    name: "Mr. Kanaaba Deus",
    position: "PRINCIPAL",
    image: "/images/principal.jpg",
  },
  {
    name: "Ms. KITUYI DOREEN",
    position: "Academic Registrar",
    image: "/images/staff/doreen.jpg", 
  },
  {
    name: "Eng. Luswabi David",
    position: "Dean of Students Affairs",
    image: "/images/staff/luswabi.jpg",
  },
  {
    name: "Mr. Kasule Fred",
    position: "Ag. Bursar",
    image: "/images/placeholder.jpg", 
  },
  {
    name: "Mr. WANGOLO DERICK",
    position: "Deputy Academic Registrar",
    image: "/images/placeholder.jpg", 
  },
  {
    name: "Mr. Mabonga Lasto",
    position: "Examinations Officer",
    image: lasito,
  },
  // Heads of Departments
  {
    name: "Ms. Najjemba Zamu Musoke",
    position: "HEAD OF DEPARTMENT Design and Technology",
    image: "/images/staff/zamu.jpg",
  },
  {
    name: "Mr. SSEMULIGO VICENT BENJAMIN",
    position: "HEAD OF DEPARTMENT BUSINESS & Mgt Studies",
    image: "/images/staff/semulingo.jpg",
  },
  {
    name: "Eng. Kyala David",
    position: "Head of Department, Technical Studies & Engineering",
    image: "/images/staff/kyala.jpg", 
  },
  {
    name: "Mr. Ssebabi Sam",
    position: "Systems Administrator",
    image: sam,
  },
];

export default staff;
