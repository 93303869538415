import React from "react";
import Header from "../components/header";

export default function DeansMessage() {
  return (
    <>
      <Header page={"academics"} />

      {/* DEAN'S MESSAGE HERO */}
      <section className="gallery-hero mb-5">
        <div className="container">
          <h1 className="text-center blue gallery-title">
            DEAN'S <span className="red">MESSAGE</span>
          </h1>
          <div className="title-underline my-3"></div>
         
        </div>
      </section>

      <div className="container mb-5">
        <div className="row">
          {/* Dean's Image Column */}
          <div className="col-lg-4 mb-4">
            <div className="card border-0 shadow h-100">
              <div className="dean-img overflow-hidden">
                <img 
                  src="/images/staff/luswabi.jpg" 
                  alt="Mr. Luswabi David, Dean of Students at Kyadondo Technical Institute"
                  className="img-fluid"
                />
              </div>
              <div className="card-body text-center bg-light">
                <h2 className="card-title capitalize blue fw-bold">Mr. Luswabi David</h2>
                <h3 className="card-subtitle mb-2 text-muted">DEAN OF STUDENTS</h3>
                <div className="d-flex justify-content-center gap-3 mt-3">
                  <a href="#" className="btn btn-sm btn-outline-primary">
                    <i className="bi bi-envelope-fill me-1"></i> Contact
                  </a>
                  <a href="#" className="btn btn-sm btn-outline-secondary">
                    <i className="bi bi-linkedin me-1"></i> Profile
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/* Message Content Column */}
          <div className="col-lg-8">
            <div className="card border-0 shadow message-card h-100">
              <div className="card-body p-4">
                <div className="message-header mb-4">
                  <h2 className="blue fw-bold mb-3">Message from the Dean of Students</h2>
                  <div className="separator-line"></div>
                </div>
                
                <div className="message-content">
                  <p className="mb-3">
                    As the Dean of Students at Kyadondo Technical Institute, I am excited to share with you the numerous benefits of pursuing technical and vocational course. In today's fast-paced, technology-driven world, employers are seeking skilled and competent individuals who can hit the ground running.
                  </p>
                  <p className="mb-3">
                    It’s a great idea to choose technical and vocational course due to its reliability to Hands-on Training since Our programs focus on practical skills development, ensuring you gain the expertise needed to succeed in your chosen field.
                  </p>
                  <p className="mb-3">
                    Job Readiness, Technical and vocational studies prepare you for immediate entry into the workforce, with many programs offering apprenticeships, internships, or on-the-job training.
                  </p>
                  <p className="mb-3">
                    Specialized Knowledge, our programs are designed to equip you with specialized skills and knowledge, making you a valuable asset to potential employers.
                  </p>
                  <p className="mb-3">
                    Career Advancement, with a solid foundation in technical and vocational studies, you'll be well-positioned for career advancement and higher salary potential.
                  </p>
                  <p className="mb-3">
                    Entrepreneurial Opportunities, many of our graduates have gone on to start their own businesses, leveraging the skills and knowledge gained through our programs.
                  </p>
                  <p className="mb-3">
                    Beyond reasonable doubt, Kyadondo technical institute remains to be the best option for you and your child, relative and friends to sharp in a professional line. I have reasons to defend my statement; Trained and Experienced Instructors, our departments comprise of experienced professionals with industry expertise, ensuring you receive guidance for both classwork and the industrial or world of work demands.
                  </p>
                  <p className="mb-3">
                    Industry Partnerships, we have established strong partnerships with local, national and international industries, providing you with opportunities for internships, apprenticeships, and job placement.
                  </p>
                  <p className="mb-3">
                    Flexible Program Options, we offer a range of programs, from certificate to diploma levels, on day, weekend and evening allowing you to choose the path that best suits your career goals.
                  </p>
                  <p className="mb-3">
                    Supportive Learning Environment, our institute is committed to providing a supportive learning environment, with resources available to help you succeed. We are located in quite and cool environment that favors learners’ concentration.
                  </p>
                  <p className="mb-3">
                    Join Kyadondo Technical Institute community today and empower yourself with the skills, knowledge, and expertise needed to succeed in today's competitive job market.
                  </p>
                  <p className="fw-bold blue mb-4">
                    Apply now and take the first step towards a brighter future!
                  </p>
                  
                  <div className="signature mt-4">
                    <div className="fw-bold fs-5 blue">Mr. Luswabi David</div>
                    <div className="text-muted">Dean of Students, Kyadondo Technical Institute-Busabala</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Call to Action Section */}
        <div className="row mt-5">
          <div className="col-12">
            <div className="card bg-blue text-white border-0 shadow">
              <div className="card-body p-4 text-center">
                <h3 className="mb-3">Ready to shape your future with us?</h3>
                <p className="mb-4">Explore our programs and start your journey toward a rewarding career.</p>
                <div className="d-flex justify-content-center gap-3">
                  <a href="/courses" className="btn btn-light red fw-bold">
                    Explore Programs
                  </a>
                  <a href="https://admissions.kti.ac.ug" className="btn btn-outline-light fw-bold">
                    Apply Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Additional Styling */}
      <style jsx>{`
        .dean-img {
          height: 300px;
          width: 100%;
        }
        
        .dean-img img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          transition: transform 0.3s ease;
        }
        
        .dean-img:hover img {
          transform: scale(1.05);
        }
        
        .separator-line {
          height: 4px;
          width: 80px;
          background-color: var(--bs-secondary);
          margin-bottom: 20px;
        }
        
        .message-card {
          position: relative;
          overflow: hidden;
        }
        
        .signature {
          border-top: 1px solid rgba(0,0,0,0.1);
          padding-top: 15px;
        }
      `}</style>
    </>
  );
}