import React from "react";
import Header from "../components/header";
import contactDesign from "../assets/contact-design.jpg";

export default function Contact() {
  return (
    <>
      {/* HEADER */}
      <Header page="contact" />

      {/* CONTACT US INFORMATION */}
      <section>
        <div className="row">
          <div className="col-12 col-md-6 mt-5 pt-3">
            <div className="container px-5 contact-info">
              <h1 className="text-center blue">
                GET IN <span className="red">TOUCH</span>
              </h1>

              <p
                className="text-justify"
                style={{ fontSize: "20px", marginTop: "30px" }}
              >
                We're here to help with any questions you might have about our
                programs, admissions, or campus life. Reach out to us using the
                contact methods below, and we'll get back to you as soon as
                possible. Your journey into the real world starts here!
              </p>

              {/* PHONE NUMBERS */}
              <h5 className="my-5">
                <img
                  src="images/phone.svg"
                  alt="..."
                  style={{ height: "50px" }}
                />
                <a href="tel:+256392548317" target={"_blank"}>
                  &nbsp; &nbsp; +256392548317 <b>|</b> +256702081313
                </a>
              </h5>

              {/* EMAIL*/}
              <h5 className="my-5">
                <img
                  src="images/email.svg"
                  alt="..."
                  style={{ height: "50px" }}
                />
                <a href="mailto: info@kti.ac.ug" target={"_blank"}>
                  &nbsp; &nbsp; info@kti.ac.ug
                </a>
              </h5>

              {/* ADDRESS */}
              <h5 className="my-5">
                <img
                  src="images/location.svg"
                  alt="..."
                  style={{ height: "50px" }}
                />
                <a href="https://maps.app.goo.gl/9pRDuQpgDF4z6Djw7" target={"_blank"}> &nbsp; &nbsp; Busabala-Makindye, Kampala Uganda.</a>
              </h5>
            </div>
          </div>

          <div className="col-6 d-flex justify-content-end">
            <img
              src={contactDesign}
              alt="..."
              className="img-fluid d-none d-md-block"
              style={{ width: "80%", height: "90%" }}
            />
          </div>
        </div>
      </section>

      {/* CONTACT FORM */}
      <section>
        <div className="container">
          <h1 className="blue">
            <strong className="red ml-5 ml-md-5"> SEND</strong> US A MESSAGE &nbsp;
            <i className="fa-solid fa-message text-warning"></i>
          </h1>
          <form className="contact-form-wrapper">
            <div className="mb-3">
              <label htmlFor="name" className="form-label">
                Name
              </label>
              <input
                type="text"
                className="form-control"
                id="name"
                aria-describedby="nameHelp"
                placeholder="Enter your name"
              />
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    aria-describedby="emailHelp"
                    placeholder="Enter your email"
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="phone" className="form-label">
                    Phone
                  </label>
                  <input
                    type="phone"
                    className="form-control"
                    id="phone"
                    aria-describedby="phoneHelp"
                    placeholder="Enter your phone number"
                  />
                </div>
              </div>
            </div>

            <div className="mb-3">
              <label htmlFor="message" className="form-label">
                Message
              </label>
              <textarea
                className="form-control"
                id="message"
                rows="3"
                placeholder="Enter your message"
              ></textarea>
            </div>
            <button type="submit" className="btn btn-success rounded-pill px-5">
              <i className="fa fa-paper-plane" aria-hidden="true"></i> &nbsp;
              Send Message
            </button>
          </form>
        </div>
      </section>

      {/* MAP */}
      <section>
        <div className="container-fluid">
          <h1 className="text-center blue mb-4 mt-5">
            <span className="red">OUR</span> LOCATION
          </h1>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1021295.5280677222!2d31.793989661018323!3d0.7935480842021553!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x177d9771b56b73cb%3A0xb82ddc378d62dd0!2sKyadondo%20Technical%20Institute!5e0!3m2!1sen!2sug!4v1711453325024!5m2!1sen!2sug"
            width={"100%"}
            height={550}
            style={{ border: "0", borderRadius: "10px" }}
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          />
        </div>
      </section>
    </>
  );
}
