const departmentsData = [
  {
    name: "Department of Technical Studies & Engineering",
    head: "Eng. Kyala David",
    description: "The Department of Technical Studies & Engineering is dedicated to providing high-quality technical and engineering education. We offer a range of programs designed to equip students with practical skills and theoretical knowledge in various engineering disciplines, preparing them for successful careers in the technical field.",
    image: "/images/staff/kyala.jpg",
    contactEmail: "kyala@gmail.com",
    phone: "07XXXXXXXXX",
    slug: "technical-studies-engineering",
    headMessage: `Welcome to the Department of Technical Studies & Engineering at Kyadondo Technical Institute.
    
  As the Head of Department, I have the privilege of leading a team of passionate educators, researchers, and professionals committed to shaping the future of engineering and  technical innovations at Kyadondo Technical Institute.
  
  Our department is a place where innovation meets education. We offer a diverse range of cutting-edge programs designed to provide students with the technical knowledge, practical experience, and problem-solving skills required to excel in the ever-evolving fields of engineering and related technology. From foundational concepts to advanced applications, our curriculum is designed to equip graduates with the expertise needed to thrive in today’s global job market.
  
  At KyadondoTechnical Institute, we are not just focused on academic excellence - we aim to create well-rounded individuals who are ready to tackle real-world challenges. Our students benefit from hands-on learning opportunities, industry partnerships, and real life projects that prepare them for the workforce. With state-of-the-art facilities, a world-class faculty, and strong ties with industry leaders, we provide an environment that encourages innovation and fosters career growth.
  
  For those considering a future in engineering, we offer a dynamic and supportive community where your academic and professional aspirations will be nurtured. Our department takes pride in being a driving force in advancing technical solutions for local and global industries. The knowledge and skills you acquire here will help shape the future of engineering, and we are committed to providing the resources and support you need to succeed.
  
  At KyadondoTechnical Institute, we are committed to not only educating the engineers and technocrats of tomorrow but also marketing our programs to those seeking a world-class  technical education. Whether you're just starting your academic journey or seeking to further your expertise, we invite you to explore our programs and discover how you can contribute to a brighter future. We are confident that the Department of Engineering and Technical Studies will offer you the knowledge, skills, and network to propel you into a successful career at both  certificate and diploma levels in the fields of;
  1. Civil  Engineering
  2. Electrical Engineering
  3. Water Engineering
  4. Automobile Engineering.
  
  Thank you for considering the Department of Engineering and Technical Studies at KyadondoTechnical Institute. We are excited to support you in achieving your goals and look forward to being a part of your educational journey.
  
  Warm regards,
  Eng. KYALA DAVID
  Head of Department, Engineering and Technical Studies`,
    courses: [
      "National Diploma in Electrical Engineering",
      "National Certificate in Electrical Installation Systems & Maintanance",
      "National Diploma in Automobile Engineering",
      "National Certificate in Automotive Mechanics",
      "National Diploma in Water & Sanitation Engineering",
      "National Certificate in Plumbing and Pipe Fittings",
      "National Diploma in Building & Civil Engineering",
      "National Certificate in Building & Construction",
    ],
  },
  {
    name: "Department of Design & Technology",
    head: "Ms. Najjemba Zamu Musoke",
    description: "The Department of Design and Technology is committed to providing students with a comprehensive education in design and technology, preparing them for successful careers in a rapidly changing world. We foster creativity, critical thinking, and technical skills, enabling students to design and develop innovative solutions.",
    image: "/images/staff/zamu.jpg",
    slug: "design-and-technology",
    headMessage: `At Kyadondo Technical Institute, we believe that design and technology are essential components of innovation and progress. Our department is committed to providing students with a comprehensive education in design and technology, preparing them for successful careers in a rapidly changing world.
  
      Our mission is to foster creativity, critical thinking, and technical skills in our students, enabling them to design and develop innovative solutions that meet the needs of society.
  
      Our vision is to be a leading department in design and technology education, recognized for excellence in teaching, research, and community engagement.
  
      We offer a range of programs in diploma, certificate, DIT, and polytechnic studies, including: Fashion and Design, Cosmetology and Beauty Theory, Art and Design, and Information Technology.`,
    courses: [
      "National Diploma in Information & Communications Technology",
      "National Certificate in Information & Communications Technology",
      "National Diploma in Cosmetology and Beauty",
      "National Certificate in Hairdressing Technology",
      "National Diploma in Fashion & Garment Design",
      "National Certificate in Fashion & Garment Design",
      "Diploma in Art & Industrial Designs",
      "Certificate in Art & Industrial Designs",
    ],
  },
  {
    name: "Department of Business & Management Studies",
    head: "Mr. Ssemuligo Vicent Benjamin",
    description: "The Department of Business & Management Studies offers a variety of competitive courses relevant to today's world of work. We are dedicated to discovering and promoting students' skills in business and management, preparing them for successful careers in various sectors.",
    image: "/images/staff/semulingo.jpg",
    slug: "business-and-management-studies",
    headMessage: `I am glad to inform you that the institute offers a variety of courses which are competitive in the world of work today. These courses are categorized into three (3) departments: Business, Technical, and Design and Technology.
  
      Specifically, the Business department offers a number of courses including: Accounting and Finance, Hotels and Institutional Catering, Journalism and Mass Communication, Tourism, and Early Childhood Department.
  
      All these courses are offered at both National Certificate and Diploma levels.
  
      I call upon you all to put your trust in Kyadondo Technical Institute by giving us your children so that we can discover and promote their skills in both Technical and Vocational courses.`,
    courses: [
      "National Diploma in Hotels & Institutional Catering",
      "National Certificate in Hotels & Institutional Catering",
      "National Diploma in Accounting & Finance",
      "National Certificate in Accounting & Finance",
      "National Diploma in Tourism & Hospitality Management",
      "National Diploma in Journalism & Media Studies",
      "National Certificate in Journalism & Media Studies",
      "Diploma in Early Childhood Development Teacher Education",
      "Certificate in Early Childhood Development Teacher Education",
    ],
  },
];

export default departmentsData;