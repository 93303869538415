import React, { useState } from "react";
import MobileHeader from "./mobile_header";
import galleryData from "../data/gallery";

export default function Header({ page }) {
  const formatTitle = (title) => {
    // Capitalize each word in the title
    return title
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const title_formatted = formatTitle(
    page || "Kyadondo Technical Institute - KTI"
  );

  // CHECK IF SCREEN SIZE IS MOBILE
  const isMobile = window.matchMedia("(max-width: 720px)").matches;

  // New Dropdown component handling its own state and events
  const Dropdown = ({ label, active, children }) => {
    const [open, setOpen] = useState(false);

    return (
      <li
        className={`dropdown ${active === true ? "active" : ""} text-left`}
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
      >
        <a
          className="dropdown-toggle"
          href={label == "Gallery" ? "/gallery" : "#"}
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded={open ? "true" : "false"}
        >
          {label}
        </a>
        <div className={`dropdown-menu${open ? " show" : ""}`}>{children}</div>
      </li>
    );
  };

  if (isMobile) {
    return <MobileHeader page={page} />;
  }

  return (
    <>
      <title>{title_formatted}</title>
      <header className="d-none d-md-block">
        {/* TOP HEADER */}
        <div className="top-header">
          <div className="row">
            <div className="col-3 d-flex justify-content-center">
              {/* FACEBOOK */}
              <a
                href="http://www.facebook.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="/images/fb.svg" alt="..." />
              </a>

              {/* TWITTER */}
              <a
                href="http://www.facebook.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="/images/x.webp" alt="..." />
              </a>

              {/* INSTAGRAM */}
              <a
                href="http://www.facebook.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="/images/ig.svg" alt="..." />
              </a>

              {/* WHATSAPP */}
              <a
                href="http://www.facebook.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="/images/wa.webp" alt="..." />
              </a>

              {/* YOUTUBE */}
              <a
                href="http://www.youtube.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="/images/yt.svg" alt="..." />
              </a>
            </div>

            {/* CONTACT DETAILS */}
            <div className="col-9 d-flex justify-content-end mr-5 pr-5 ">
              {/* Location */}
              <span className="d-none d-md-block">
                <a href="#">
                  <img src="/images/location.svg" alt="..." />{" "}
                  Busabala-Makindye, Kampala Uganda
                </a>
              </span>

              {/* phone */}
              <span>
                <a href="tel:+256392548317">
                  <img src="/images/phone.svg" alt="..." /> +256392548317 |
                  +256702081313
                </a>
              </span>

              {/* email */}
              <span className="padd-right">
                <a href="mailto:info@kti.ac.ug">
                  <img src="/images/email.svg" alt="..." /> info@kti.ac.ug
                </a>
              </span>
            </div>
          </div>
        </div>

        <div className="row header sticky-top">
          {/* LOGO */}
          <div className="col-md-3">
            <div className="logo">
              <a href="/">
                <img className="img-fluid" src="/images/logo.png" alt="#" />
              </a>
            </div>
          </div>

          {/* NAV MENU ITEMS */}
          <div className="col-md-7">
            <div className="menu-area">
              <nav className="main-menu ">
                <ul className="menu-area-main">
                  <li className={page === "home" ? "active" : ""}>
                    <a href="/">Home</a>
                  </li>

                  <Dropdown label="About" active={page === "staff"}>
                    <a href="/about" className="text-secondary">
                      About KTI
                    </a>
                    <div className="dropdown-divider"></div>

                    <a href="/principal-message" className="text-secondary">
                      Principal's Message
                    </a>
                    <div className="dropdown-divider"></div>

                    <a href="/leadership" className="text-secondary">
                      Leadership Team
                    </a>
                  </Dropdown>

                  {/* ACADEMICS Dropdown */}
                  <Dropdown label="Academics" active={page === "academics"}>
                    <a className="text-secondary" href="/ar-message">
                      AR's Message
                    </a>
                    <div className="dropdown-divider"></div>

                    <a className="text-secondary" href="/deans-message">
                      Dean's Message
                    </a>
                    <div className="dropdown-divider"></div>
                    <a className="text-secondary" href="/departments">
                      Departments
                    </a>

                    <div className="dropdown-divider"></div>
                    <a href="/admission" className="text-secondary">
                      Admission
                    </a>
                  </Dropdown>

                  {/* OUR COURSES */}
                  <Dropdown
                    label="Courses & Fees Structure"
                    active={page === "courses"}
                  >
                    <a href="/courses" className="text-secondary">
                      Courses
                    </a>
                    <a
                      className="text-secondary"
                      href="/fees_structure.pdf"
                      target="_blank"
                    >
                      Fees Structure
                    </a>
                  </Dropdown>

                  {/* GALLERY Dropdown */}
                  <Dropdown label="Gallery" active={page === "gallery"}>
                    {Object.entries(galleryData).map(([category, data]) => (
                      <div key={category}>
                        <a
                          href={`/gallery/${category}`}
                          className="text-secondary"
                        >
                          {data.title}
                        </a>
                        <div className="dropdown-divider"></div>
                      </div>
                    ))}
                  </Dropdown>

                  {/* ADMISSIONS */}
                  <li className={page === "admission" ? "active" : ""}></li>

                  <li className={page === "contact" ? "active" : ""}>
                    <a href="/contact">Contact</a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>

          {/* APPLY BUTTON */}
          <div className="col-md-2 d-flex align-items-center justify-content-center">
            <a href="https://admissions.kti.ac.ug">
              <button className="btn btn-warning rounded-pill px-4">
                APPLY NOW
              </button>
            </a>
          </div>
        </div>
      </header>
    </>
  );
}
