import React from "react";

export default function ManagementBoard() {
  // Board members data
  const boardMembers = [
    { title: "Principal", name: "Mr. Kanaaba Deus" },
    { title: "Academic Registrar", name: "Ms. KITUYI DOREEN" },
    { title: "Deputy Academic Registrar", name: "Mr. WANGOLO DERICK" },
    { title: "Dean of Students", name: "Eng. Luswabi David" },
    { title: "Ag. Bursar", name: "Mr. Kasule Fred" },
    { title: "Examination Officer", name: "Mr. Mabonga Lasto" },
    { title: "Estates Officer", name: "" },
    { title: "Secretary", name: "" },
    { title: "Heads of Departments", name: "Various" }
  ];

  // Board responsibilities
  const responsibilities = [
    "Oversee the quality assurance of the academic work of KTI.",
    "Promote excellence in teaching and learning.",
    "Facilitate free intellectual inquiry and ensure academic integrity.",
    "Ensure a culture of scholarship is developed and nurtured within KTI.",
    "Consider and make decisions on all aspects of the development and accreditation or re-accreditation of higher education courses, the admission of students, teaching, assessment and requirements for graduation, prizes, awards, and scholarships.",
    "Formulate, coordinate, and review academic policy, procedures, and guidelines within KTI.",
    "Advise on the academic aspects of KTI's strategic, operational, and risk management plans and to foster discourse on issues related to higher education and KTI's Vision and Goals.",
    "Consider and recommend on any matter referred to the Academic Board by the Board of Directors.",
    "In addition to such matters as are specifically referred to the Academic Board, the Academic Board may generate reports and recommendations to the Board of Directors, including recommending new courses for development."
  ];

  return (
    <div className="academic-board-container">
      <div className="row mt-5">
        <div className="col">
          <div className="card border-0 shadow-sm">
            <div className="card-body p-4">
              <h3 className="text-center mb-4">
                <span className="blue">MANAGEMENT</span>{" "}
                <span className="red text-bold">BOARD</span>
              </h3>
              
              <div className="board-intro mb-4">
                <p className="lead">
                  The KTI management board is an organ of the Institution
                  mandated to advise the Governing Council on all academic matters
                  and questions affecting the educational policy of KTI.
                </p>
              </div>

              <div className="row">
                {/* Board Members Column */}
                <div className="col-md-5">
                  <div className="card bg-light mb-4">
                    <div className="card-header bg-primary text-white">
                      <h4 className="mb-0">Board Composition</h4>
                    </div>
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table table-hover">
                          <thead>
                            <tr>
                              <th scope="col">Position</th>
                              <th scope="col">Member</th>
                            </tr>
                          </thead>
                          <tbody>
                            {boardMembers.map((member, index) => (
                              <tr key={index}>
                                <td>{member.title}</td>
                                <td>{member.name || "—"}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Responsibilities Column */}
                <div className="col-md-7">
                  <div className="card bg-light">
                    <div className="card-header bg-primary text-white">
                      <h4 className="mb-0">Board Responsibilities</h4>
                    </div>
                    <div className="card-body">
                      <div className="responsibilities-list">
                        {responsibilities.map((responsibility, index) => (
                          <div className="responsibility-item" key={index}>
                            <div className="responsibility-number">{index + 1}</div>
                            <div className="responsibility-text">{responsibility}</div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Additional Styling */}
      <style jsx>{`
        .bg-primary{
            background-color: #200769 !important;
        }
     
        .responsibilities-list {
          display: flex;
          flex-direction: column;
          gap: 12px;
        }
        
        .responsibility-item {
          display: flex;
          align-items: flex-start;
          gap: 12px;
        }
        
        .responsibility-number {
          display: flex;
          justify-content: center;
          align-items: center;
          min-width: 28px;
          height: 28px;
          background-color: #200769;
          color: white;
          border-radius: 50%;
          font-weight: 600;
          flex-shrink: 0;
        }
        
        .responsibility-text {
          padding-top: 3px;
        }
        
        @media (max-width: 768px) {
          .board-intro {
            border-left: none;
            padding-left: 0;
            border-bottom: 2px solid var(--bs-primary);
            padding-bottom: 15px;
          }
        }
      `}</style>
    </div>
  );
}