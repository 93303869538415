import React from "react";
import Header from "../components/header";
import AcademicBoard from "../components/management_board";

export default function About() {
  return (
    <>
      <style jsx>
        {`
          .mission img {
            width: 100%;
            height: 150px;
          }
        `}
      </style>
      {/* HEADER */}
      <Header page="about" />

      <div>
        <section className="page-header">
        <h1 className="blue mt-5 text-center mb-4">
          ABOUT <span className="red">KYADONDO TECHNICAL INSTITUTE</span>
        </h1>
        </section>
        <div className="row">
          <div className="col-md-7">
            <div style={{ paddingInline: "30px" }} className="mission">
              <p>
                Kyadondo Technical Institute is a Multicultural Business &
                Technical Training Institute located in Busabala-Makindye
                Kampala. It is established and fully registered by the Ministry
                of Education and Sports Registration no. ME/VOC/421, accredited
                by the Directorate of Industrial Training. <br />
                The Institute also collaborates with reputable academic
                institutions in the country like Makerere University Kampala,
                Islamic University in Uganda, and others. The Institute is a
                National Examining Centre for UBTEB, DIT & ECD. The Institute
                Centre Numbers are BTV529, UBT416, UBB272, DIT216, and ECD422.
                It offers a variety of courses in Polytechnic Certificates (PLE
                leavers), National Certificates Courses (UCE leavers or its
                equivalent), and National Diploma Courses (UACE leavers or its
                equivalent).
              </p>

              {/* MISSION */}
              <div className="row mt-4">
                <div className="col-md-4">
                  <img
                    src="/images/mission.svg"
                    alt="Mission"
                    className="img-fluid mb-3"
                  />
                </div>
                <div className="col-md-8">
                  <h2>Our Mission</h2>
                  <p>
                    To equip students with lifelong learning skills based on
                    community needs.
                  </p>
                </div>
              </div>

              <hr />

              {/* VISION */}
              <div className="row">
                <div className="col-md-4">
                  <img
                    src="/images/vision.jpg"
                    alt="Vision"
                    className="img-fluid mb-8"
                  />
                </div>
                <div className="col-md-6">
                  <h2>Our Vision</h2>

                  <p>Transforming lives through skills development.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-5 d-flex justify-content-center align-items-center">
            <img
              src="images/building.jpg"
              alt="..."
              style={{
                borderRadius: "15px",
                marginRight: "10px",
              }}
            />
          </div>
        </div>

        <div className="make text-white">
          <div className="container" style={{ paddingInline: "20px" }}>
            <div className="row">
              <div className="col-md-12">
                {/* CORE VALUES */}
                <h3 className="mb-4">
                  CORE <span className="yellow">VALUES</span>
                </h3>
              </div>
            </div>
            <div className="row">
              <p>
                Our core values are at the heart of our approach to student
                success. These principles guide our interactions, shape our
                assemblies, and underpin the systems of recognition and
                accountability at the Institute, reflecting the excellence we
                strive for in the KTI community.
              </p>

              <div className="ml-5">
                <ol>
                  <li>
                    <strong className="yellow">Quality:</strong> Committing to
                    excellence and continuous improvement in every aspect of our
                    work.
                  </li>
                  <li>
                    <strong className="yellow">Accountability:</strong> Owning
                    our actions and decisions, ensuring responsibility in all
                    our commitments.
                  </li>
                  <li>
                    <strong className="yellow">Integrity:</strong> Upholding
                    honesty and strong moral principles in every interaction.
                  </li>
                  <li>
                    <strong className="yellow">Respect:</strong> Valuing every
                    individual, fostering an inclusive and supportive
                    environment.
                  </li>
                  <li>
                    <strong className="yellow">
                      Responsiveness to Students' Success:
                    </strong>{" "}
                    Actively addressing student needs and promoting their
                    academic and personal growth.
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        {/* ACADEMIC BOARD */}
        <AcademicBoard />
        

        {/* GOVERNANCE */}
        <div className="container-fluid" style={{ paddingInline: "20px" }}>
          <h3 className="blue mt-3">GOVERNANCE</h3>
          The Governing Council (GC) is the top Management organ of Institute.
          This council undertakes its mandate in accordance with the BTVET Act
          of 2008. It is headed by the able Chairman of great reputation as well
          as an academia and draws its membership from outstanding members of
          the Ugandan society. Specifically, the GC sets out internal policies
          and development priorities for the institute and approves the budget
          for both recurrent and development expenditure. The GC is also
          responsible for the implementation of government policies, approving
          the Institutional Curriculum, ensuring safety of students and staff,
          overseeing all other institutional programs as well as steering growth
          and development of the entire school.
        </div>
      </div>
    </>
  );
}
