import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import courses from '../data/courses'; 
import Header from '../components/header';

const CourseDetails = () => {
  const { id } = useParams();
  const [course, setCourse] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

const contactNumbers = ["0392548317", "0782954910", "0702081313"];
const contactEmails = ["info@kti.ac.ug", "sserwanjaasadu@gmail.com"];
const location = "Busaabala trading center";
const applyLink = "https://admissions.kti.ac.ug";


useEffect(() => {
  setLoading(true);
  setError(null);

  const fetchCourse = async () => {
      try {
          const foundCourse = courses[id]; // Synchronous access, no real async here

          if (foundCourse) {
              setCourse(foundCourse);
              setLoading(false);
          } else {
              setError({ message: 'Course not found' });
              setLoading(false);
          }
      } catch (err) {
          setError(err);
          setLoading(false);
      }
  };

  fetchCourse();

  return () => { // Add this cleanup function
      // No cleanup needed for simple synchronous data access, but include it
      // to potentially help React manage the effect lifecycle better.
  };
}, [id]);

  if (loading) {
    return (
      <div className="container mt-5 text-center">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container mt-5 text-center text-danger">
        Error loading course details: {error.message}
      </div>
    );
  }

  if (!course) {
    return (
      <div className="container mt-5 text-center">
        <h2>Course not found</h2>
      </div>
    );
  }

  return (
    <>
      <Header />
      <div className="container mt-4 course-details-container">
        <div className="row">
          <div className="col-md-6">
            <img
              src={course.image}
              alt={course.title}
              className="img-fluid rounded-3 mb-4 course-image"
            />
          </div>
          <div className="col-md-6">
            <div className="course-info">
              <h1 className="course-title mb-3">{course.title}</h1>
              <p className="course-description text-muted mb-4">
                {course.overview}
              </p>

              <div className="mb-3">
                <span className="detail-label">Course Type:</span>{' '}
                <span className="detail-value">{course.category}</span>
              </div>
              <div className="mb-3">
                <span className="detail-label">Duration:</span>{' '}
                <span className="detail-value">{course.duration}</span>
              </div>
              <div className="mb-4">
                <span className="detail-label">Entry Requirements:</span>
                <ul className="detail-list">
                  {course.entryRequirements.map((req, index) => (
                    <li key={index} className="detail-list-item">{req}</li>
                  ))}
                </ul>
              </div>


              <div className="apply-button mt-4">
                <a
                  href={applyLink}
                  className="btn btn-success rounded-pill px-5 py-2 apply-now-btn"
                >
                  APPLY NOW
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="course-content mt-5">
          <h2 className="content-heading mb-3">Course Structure</h2>
          <ul className="list-group">
            {course.syllabus.map((item, index) => (
              <li key={index} className="list-group-item course-syllabus-item">
                {item}
              </li>
            ))}
          </ul>
        </div>

        <div className="career-prospects mt-5">
          <h2 className="content-heading mb-3">Career Prospects</h2>
          <ul className="list-group">
            {course.careerProspects.map((prospect, index) => (
              <li key={index} className="list-group-item course-syllabus-item">
                {prospect}
              </li>
            ))}
          </ul>
        </div>

        <div className="additional-info mt-5">
          <h2 className="additional-heading mb-3">Additional Information</h2>
          <p className="additional-text">
            Upon successful completion, students are awarded the National Diploma examined by the {course.awardingBody}. <br/><br/>
            For admissions and further details: <br/>
            📞 Contact Us: {contactNumbers.join(" / ")} <br/>
            📧 Email: {contactEmails.join(" / ")} <br/>
            📍 Location: {location} <br/>
          </p>
        </div>
      </div>
    </>
  );
};

export default CourseDetails;